import axios from 'axios';
import React, { Component } from 'react'
import Banner from '../Banner';
import Magazine from '../Magazine';
import RightBanner from '../RightBanner';
import Header from './Header3';
import _ from "lodash";
import {message} from 'antd';

export default class Fiche extends Component {
    constructor(props){
        super(props);
        this.state={
            fichDetails : {}
        }
        this.pushdist = this.pushdist.bind(this)
      }
    

    pushdist = () => {
        this.props.history.push({
            pathname: '/distributionprofile'
        });
    }

    getFicheDetails = (id) => {
        axios.get(`https://tunisieimmob.herokuapp.com/api/fiche/${id}`)
            .then((response) => {
                console.log(response);
                if(response.data){ console.log("true");} else {
                    message.error("annonce not found ");
                    this.props.history.push({pathname : '/'})
                }
                console.log("false");
                
                this.setState({
                    fichDetails: response.data
                })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    componentDidMount(){
        window.scrollTo(0, 0)
        const id = this.props.match.params.id;
        console.log(id);
        this.getFicheDetails(id);
    }
    render() {
        return (
            <div>
                <Header active={"distribution"} pushdist={this.pushdist}  />
                <Banner />
                {/* <div className="banniere1 default-padding">
                    <div id="carousel-example-generic0" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators"> 
                        </ol>
                        <div className="carousel-inner" role="listbox">
                            <div className="item active">
                                <img width="100%" src="assets/img/point-distribution/1.jpg" alt="..." />
                            </div>
                        </div>
                     
                        <a className="left carousel-control" href="#carousel-example-generic0" role="button" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />
                        </a>
                        <a className="right carousel-control" href="#carousel-example-generic0" role="button" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
                        </a>
                    </div>
                </div>
                */}
                <div className="container default-padding">
                    <div className="row" >

                    { !_.isEmpty(this.state.fichDetails) ?( <div className="col-md-8">
                            <div className="utf-site-heading">
                                <h4>cafe {this.state.fichDetails['titre']} </h4>
                            </div >
                            <table  > 
                                <tr>
                                    <td  style={{ width: "300px" , border : "none"}} >
                                        <img width="230px" height="230px" src={this.state.fichDetails['photo'][1]} />
                                    </td>
                                    <td  style={{border : "none"}} >
                                        <div className="utf-contact-area default-padding">
                                            <div style={{ width: "400px" }} className=" address-info left-info">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-map-marker-alt" />
                                                        <p>détails </p>
                                                    </li>
                                                    <li className="content" >
                                                        <div className="row">
                                                            <div className="col-md-4" > <strong>Adresse : </strong>  {this.state.fichDetails['adresse']} </div>
                                                            <div className="col-md-4" ><strong> Ville : </strong> {this.state.fichDetails['ville']} </div>
                                                            <div className="col-md-4" > <strong>Gouvernorat : </strong> {this.state.fichDetails['gouvernorat']} </div>
                                                            
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-map-marker-alt" />
                                                        <p>Contact </p>
                                                    </li>
                                                    <li className="content" >
                                                        <div className="row">
                                                            <div className="col-md-6" > <strong>Téléphone :</strong>{this.state.fichDetails['tel']} </div>
                                                            <div className="col-md-6" > <strong>facebook : </strong>  {this.state.fichDetails['facebook']} </div>
                                                          
                                                        </div>
                                                    </li>

                                                </ul>

                                            </div>


                                        </div>

                                    </td>
                                </tr>
                             
                            </table>
                           <br></br>
                           <div className="utf-site-heading">
                                <h4>galerie images   </h4>
                            </div >
                            <div className="row" >
                                <ul>
                                    <li className="galeriedist">
                                            {this.state.fichDetails['photo'].map((element)=>{
                                                return(
                                                    <img width="230" height="230" src={element}/>
                                                )
                                               
                                            })  }
                                     
                                    </li>
                                </ul>
                            </div>
                        </div>
                       ) :( <div> </div> ) }
                        
                      
                        <div className="col-md-4">
                            <Magazine />
                            <br>
                            </br>
                            <RightBanner />
                            <br></br>
                        </div>

                    </div>

                </div>


                <footer className="text-center bg-dark text-light">
                    <div className="container">
                        <div className="col-md-3">
                            <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
                            <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
                            un
                            contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li id="contact_widget-2" className="widget-container contact_sidebar">
                                    <h4>Contact siège à Tunis</h4>
                                    <div>
                                        <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                                        <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                        894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                                        <p> </p>
                                        <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                                    </div>
                                </li>
                                <li id="contact_widget-1" className="widget-container contact_sidebar">
                                    <h4>Contact siège à Sousse</h4>
                                    <div className="contact_sidebar_wrap">
                                        <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                                        <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                        210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                                        <p />
                                        <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                                    <h4>Nos sites</h4>
                                    <div className="textwidget">
                                        <ul>
                                            <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                                            <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                                            </li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                                            <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h4>Rejoignez-nous !</h4>
                            <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
                            </div>
                            <div className="col-md-3">
                                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
                            </div>
                            <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
                            </div>
                            <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer2">
                    <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
                </div>
            </div>
        )
    }
}
