import { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';  // Import BrowserRouter
import Accueil from './Components/Accueil';
import Magazines from './Components/Magazines';
import Promotion from './Components/Promotion';
import Aboutus from './Components/Aboutus';
import Annonces from "./Components/Annonces";
import Annoncespro from "./Components/Annoncespro";
import Distributions from "./Components/espacedist/Distributions";
import Profile from "./Components/profile";
import Addannonce from './Components/Addannonce';
import Mesannonces from './Components/Mesannonces';
import Annonce from './Components/Annonce';
import My404Component from './Components/PageNotFound';
import AnnoncesParVille from './Components/AnnonceParVille';
import Platformepro from './Components/Platformepro';
import Magazinepdf from './Components/Magazinepdf';
import Annoncesresult from './Components/Annoncesresult';
import Profiledist from './Components/espacedist/profiledist';
import Editfiche from './Components/espacedist/Editfiche';
import Fiche from './Components/espacedist/fiche';
import Mafiche from './Components/espacedist/Mafiche';

class App extends Component {
  render() {
    return (
      <div>
        {/* Replace HashRouter with BrowserRouter */}
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Accueil} />
            <Route exact path='/fiche/:id' component={Fiche} />
            <Route exact path='/mafiche' component={Mafiche} />
            <Route exact path='/annonces-des-professionnels' component={Annoncespro} />
            <Route exact path='/liste-des-annonces-toutes' component={Annonces} />
            <Route exact path='/distribution' component={Distributions} />
            <Route exact path='/Annoncesresult/:ville/:categories/:type' component={Annoncesresult} />
            <Route exact path='/platforme-professionnelle' component={Platformepro} />
            <Route exact path='/presentation' component={Aboutus} />
            <Route exact path='/promotion-immobiliere-en-tunisie' component={Promotion} />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/distributionprofile' component={Profiledist} />
            <Route exact path='/Editfiche' component={Editfiche} />
            <Route exact path='/ajout-annonce' component={Addannonce} />
            <Route exact path='/mesannonces' component={Mesannonces} />
            <Route exact path='/annonce/:id' component={Annonce} />
            <Route exact path='/annonces/:ville' component={AnnoncesParVille} />
            <Route exact path='/magazines' component={Magazines} />
            <Route exact path='/magazinepdf' component={Magazinepdf} />
            <Route path='*' exact component={My404Component} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
