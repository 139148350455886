import axios from 'axios';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Header from './Header';
import Banner from './Banner';
import LogoClient from './LogoClient';
import RightBanner from './RightBannerIntern';


export class Mags extends Component {
  render() {
    console.log(this.props.mags)
    return (
      <div>
        {this.props.mags.map((element) => {
          return (
            <div className="pf-item Creative Designer photography">
              <div className="item-effect"> <img width={280} hight={390} src={element["image"]} />
                <div className="bottom-info">
                  <a href={element["url"]} target="_blank" className="item utf-popup-link"> <span className="glyphicon glyphicon-download-alt" /> </a>
                </div>
              </div>
              <div className="item-info">
                <h6><a>{element["numero"]} </a></h6>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
class Magazines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      magazines: [],
      offset:0,
      orgMagazines: [],
      perPage: 9,
      currentPage: 0
    }
    this.pushProfile = this.pushProfile.bind(this)
  }
  pushProfile = () => {
    this.props.history.push({
      pathname: '/ajout-annonce'
    });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };






  loadMoreData() {
    const data = this.state.orgMagazines;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      magazines: slice
    })

  }

  getMagzine = () => {
    axios.get('https://tunisieimmob.herokuapp.com/api/magazine')
      .then((response) => {
        console.log(response.data);
        var data = response.data;
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);
        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgMagazines: response.data,
          magazines: slice,
        });
      })
      .catch((error) => {
        console.log(error.response);
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getMagzine();
  }
  render() {
    return (
      <div>
        <Header active={"magazines"} pushProfile={this.pushProfile} />
        <Banner />
        <div className="utf-about-area-item about-us default-padding">
          <div className="container">

            {this.state.orgMagazines[0] ?
              (<div className="row">
                <div className="col-md-8">
                  <div className="utf-site-heading">
                    <h4>Tunisie immobilier magazine</h4>
                  </div>
                  <div className="editionMag">
                    <div>
                      <h3 className="item-effects">Magazine Edition {this.state.orgMagazines[0]["numero"]}  <a className="btn btn-theme effect btn-xs" href={this.state.orgMagazines[0]["url"]} target="_blank">Télécharger <span className="glyphicon glyphicon-download-alt" aria-hidden="true" /></a> </h3>

                      <p className="item-effects">
                        {this.state.orgMagazines[0]["description"]}
                      </p>
                      <img className="item-effects" width={370} hight={450} src={this.state.orgMagazines[0]["image"]} />
                    </div>
                  </div>


                </div>
              </div>


              )
              :
              (<div></div>)
            }
          </div>
        </div>
        {/* tous les edition  */}
        <div className="utf-portfolio-area-item info-shadow bg-gray default-padding text-center">
          <div className="container">
            <div className="utf-portfolio-items-area">
              <div className="row">
                <div className="col-md-8 utf-portfolio-content">
                  <div className="row utf-site-heading ">
                    <h4>Les dernières éditions du tunisie immobilier magazine</h4>
                  </div>
                  <div className="row magnific-mix-gallery effect-up masonary text-light">
                    <div id="portfolio-grid" className="utf-portfolio-items">
                      <Mags mags={this.state.magazines} />
                    </div>
                  </div>
                  <div className="row pagination">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  {/* End  Row */}
                </div>
                <div className="col-md-4">
                  <div className="rightbannerMag">
                    <RightBanner />
                  </div>
                  <br />
                  <div className="rightbannerMag">
                    <RightBanner />
                  </div>
                  <div>
                    <br />
                    <div className="box-sidebar">
                      <b> REJOIGNEZ-NOUS !</b>
                      <a> <img src="assets/img/capture3.JPG" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* start logo-clients */}
        <LogoClient />
        {/* Start Footer*/}
        <footer className="text-center bg-dark text-light">
          <div className="container">
            <div className="col-md-3">
              <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
              <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
                un
                contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="contact_widget-2" className="widget-container contact_sidebar">
                  <h4>Contact siège à Tunis</h4>
                  <div>
                    <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                      894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                    <p> </p>
                    <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                  </div>
                </li>
                <li id="contact_widget-1" className="widget-container contact_sidebar">
                  <h4>Contact siège à Sousse</h4>
                  <div className="contact_sidebar_wrap">
                    <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                      210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                    <p />
                    <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                  <h4>Nos sites</h4>
                  <div className="textwidget">
                    <ul>
                      <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                      </li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Rejoignez-nous !</h4>
              <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
              </div>
              <div className="col-md-3">
                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer2">
          <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
        </div>
      </div>
    )
  }
}
export default Magazines
