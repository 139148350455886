import React, { Component } from "react";
import { Form, Input, Button, Upload, Image, Row, Col, Layout, Menu, } from "antd";
import {
  UploadOutlined,
  UserOutlined,
  PlusOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import S3 from "react-aws-s3";
import axios from "axios";
import _ from 'lodash';
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import Header2 from "./Header2"
const { TextArea } = Input;
const { Header, Content, Footer, Sider } = Layout;
const config = {
  bucketName: "tunisie-immob",
  /*  dirName: 'media', optional */
  region: "eu-west-3",
  accessKeyId: "AKIAXVWFAUBHFUM7UQ24",
  secretAccessKey: "2w/tBnEYOmQgGThMEdS7JiccnMeV0DP4fXF1sYbi",
  /* s3Url: 'https:/your-custom-s3-url.com/',  optional */
};
const ReactS3Client = new S3(config);
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};
const layoutt = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};
const layout2 = {
  labelCol: {
    span: 14,
  },
  wrapperCol: {
    span: 16,
  }
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16,
  },
};

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualUser: "",
      userInformation: {},
      imageUrl : ""
    };
  }
  forceReloder = () => {
    console.log('force reload', this.state.actualUser);
    this.getUserInformation(this.state.actualUser);
  }
  getUserInformation = (id) => {
    axios
      .get(`https://tunisieimmob.herokuapp.com/api/users/${id}`)
      .then((response) => {
        console.log(response.data);
        this.setState({ userInformation: response.data , imageUrl: response.data.logo});
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleLogOut = () => {
    this.props.history.push({
      pathname: '/'
    });
    localStorage.removeItem("id")
    localStorage.removeItem("username")
    localStorage.removeItem("password")
    localStorage.removeItem("email")

  //  localStorage.clear();
  }
  componentDidMount() {

    const userid = localStorage.getItem("id");
console.log(userid);
    this.setState({ username: localStorage.getItem("username") });

    this.setState({ userpassword: localStorage.getItem("password") });

    this.setState({ useremail: localStorage.getItem("email") });

    this.setState({ actualUser: userid });



    this.getUserInformation(userid);
  }

  handleSubmit = (e) => {
    
    console.log(typeof(e.avatar));
    console.log(e.avatar);
    if (e.avatar) {
      ReactS3Client
        .uploadFile(e.avatar.file, "avatar2")
        .then(data => {
          localStorage.setItem('imageUrl', data.location);
          console.log(data.location);
        }
        )
        .catch(err => console.log("error " + err))
    }
    const id = this.state.actualUser;
    const imageUrl = localStorage.getItem('imageUrl');
    console.log(imageUrl);
    axios
    .put(`https://tunisieimmob.herokuapp.com/api/users/${id}`, {
      aboutme: e.aboutme,
      act: "",
      adresse: e.adresse,
      email: this.state.useremail,
      facebook: e.facebook,
      fax: e.fax,
      gouvernorat: e.gouvernorat,
      gsm: e.gsm,
      id: id,
      instagram: e.instagram,
      linkedin: e.linkedin,
      logo: imageUrl,
      nom: e.nom,
      ordre: 0,
      password: this.state.userpassword,
      position: e.position,
      prenom: e.prenom,
      skype: e.skype,
      statut: "",
      tel: e.phone,
      twitter: e.twitter,
      username: this.state.username,
      ville: e.ville,
      web: e.web,
    })
    .then((response) => {
     this.forceReloder();

      console.log(response);
      localStorage.removeItem('imageUrl')
    })
    .catch((error) => {
      console.log(error);
    });
  };
  handleSubmitPassword = (e) => {

    console.log(e)

  };

  render() {
    const props = {
      name: 'file',
      multiple: false,
      showUploadList: {
          showDownloadIcon: false,
      },
      beforeUpload: file => {
          this.setState(state => ({
              fileList: [file]
          }))
          return false;
      },
    };
    return (
      <div style={{ marginTop: "87px" }}>
        <Header2 handleLogOut={this.handleLogOut} />
       
        <Layout>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
          >
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1" icon={<UserOutlined />}>
                 <Link to="/profile" >Mon Profile</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<BarsOutlined />}>
                 <Link to="/mesannonces" >Mes annonces</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<PlusOutlined />}>
                 <Link to="/ajout-annonce" >Ajouter une annonce</Link>
              </Menu.Item>
              {/* <Menu.Item key="4" icon={<HeartOutlined />}>
                favoris
              </Menu.Item>
              <Menu.Item key="5" icon={<FileSearchOutlined />}>
                Recherche enregistrées
              </Menu.Item> */}
            </Menu>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200 }}>
            <Header className="site-layout-background" style={{ padding: 0 }} />
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div
                className="site-layout-background"
              >
                {!_.isEmpty(this.state.userInformation) ? (
                <Form className="login-form" onFinish={this.handleSubmit}
                  initialValues={{
                    //'avatar': this.state.userInformation.logo,
                    'nom': this.state.userInformation.username,
                    'prenom':this.state.userInformation.prenom,
                    'tel':this.state.userInformation.tel ,
                    'gsm':this.state.userInformation.gsm,
                    'adresse':this.state.userInformation.adresse,
                    'gouvernorat': this.state.userInformation.gouvernorat,
                    'ville':this.state.userInformation.ville,
                    'fax': this.state.userInformation.fax,
                    'email':this.state.userInformation.email,
                    'skype':this.state.userInformation.skype,
                    'facebook':this.state.userInformation.facebook,
                    'instagram': this.state.userInformation.instagram,
                    'linkedin':this.state.userInformation.linkedin,
                    'twitter':this.state.userInformation.twitter,
                    'web':this.state.userInformation.web,
                    'position':this.state.userInformation.position,
                    'aboutme':this.state.userInformation.aboutme,
                  }}>
                  <br />
                  <br />
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="Photo">
                        <Image width={200} height={200} src={this.state.imageUrl} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6} >
                      <Form.Item
                        {...layout}
                        label="user image"
                        name="avatar"
                      >
                        <Upload {...props}>
                          <Button icon={<UploadOutlined />}>
                            Upload profile picture
                        </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <hr />
                  <br />
                  <h5>User Details</h5>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="First Name" name="nom">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...layout} label="Last Name" name="prenom" >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row  >
                    <Col span={6}>
                      <Form.Item  {...layout} label="phone" name ="tel">
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item {...layout} label="gsm" name="gsm" >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="adresse" name="adresse" >
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...layout} label="gouvernorat" name="gouvernorat">
                        <Input   />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="ville" name="ville">
                        <Input  />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...layout} label="fax" name="fax" >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="email" name="email">
                        <Input  disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...layout} label="skype" name="skype" >
                        <Input  />
                      </Form.Item>
                    </Col>
                  </Row>
                  <hr />
                  <br />
                  <h5>User Details social media informations </h5>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="facebook" name="facebook">
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item {...layout} label="instagram"  name="instagram">
                        <Input  />
                      </Form.Item>
                    </Col>
                  </Row><Row>
                    <Col span={6}>
                      <Form.Item  {...layout} label="linkedin" >
                        <Input name="linkedin"  />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item {...layout} label="twitter" >
                        <Input name="twitter"  />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item  {...layout} label="web" >
                        <Input name="web"  />
                      </Form.Item>
                    </Col>


                  </Row>
                  <hr />
                  <br />
                  <h5>User Details add some information about yourself </h5>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layout} label="position" >
                        <Input name="position"  />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item {...layoutt} label="about me" >
                        <TextArea name="aboutme" rows={8}  />
                      </Form.Item>
                    </Col>
                  </Row>

                  <hr />
                  <br />

                  <Form.Item {...tailLayout} >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Update Profile
  </Button>
                  </Form.Item>
                </Form>
                ) : (<div> </div>)}
                <hr />
                <br />
                <Form onSubmit={this.handleSubmitPassword} className="login-form">

                  <h5>Change password</h5>

                  <Row>
                    <Col span={6}>
                      <Form.Item  {...layout} label="Old Password"
                        name="oldpassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]} >
                        <Input
                          value="old password"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item {...layout} label="new password" name="newpassword" rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}  >
                        <Input value="New password" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item  {...layout} label="Confirm Password"
                        name="confirmnewpassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]} >
                        <Input
                          placeholder="Confirm Password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Update Password
                   </Button>
                  </Form.Item>
                </Form>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Ant Design ©2018 Created by Ant UED
            </Footer>
          </Layout>
        </Layout>
      </div>

    );
  }
}
