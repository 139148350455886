import React, { Component } from 'react';
import Carousel from "react-elastic-carousel";
import axios from "axios";
import _ from 'lodash';
import Item from 'antd/lib/list/Item';
import { Link } from 'react-router-dom';

export default class ItemPro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            annonces: [],

        }
    }
    getAnnoncesPro() {
        axios.get('https://tunisieimmob.herokuapp.com/api/annoncebytypeandetat/professionnelle/valide')
            .then((response) => {
                console.log(response.data);
                this.setState({ annonces: response.data })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    componentDidMount() {
        this.getAnnoncesPro();
    }
    fromStringToDate(d) {
        var months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        const date = new Date(d);
        var elapsed = date.getDay() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        return (elapsed);
    }
    //  onNextStart = (currentItem, nextItem) => {
    //     if (currentItem.index === nextItem.index) {
    //       // we hit the last item, go to first item
    //       goTo(0);
    //     }
    //   };

    //    onPrevStart = (currentItem, nextItem) => {
    //     if (currentItem.index === nextItem.index) {
    //       // we hit the first item, go to last item
    //      goTo(this.state.annonces.length);
    //     }
    //   };
    render() {
        const breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 3, itemsToScroll: 1 },
            { width: 768, itemsToShow: 4 },
            { width: 1200, itemsToShow: 4 }
        ];
        const items = this.state.annonces;

        return (
            <div>
                {!_.isEmpty(items) ? (
                    <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500} pagination={false}

                    >
                        {/**/}
                        {items.map((element) => {
                            return (
                                <Item>
                                    <div style={{ margin: "15px" }} className="item-pro">
                                        <Link to={`/annonce/${element["id"]}`}>
                                            <img src={element["photo"][0]} alt="" />
                                            <p className="prix" > {element["prix"]} TND </p>

                                            <p className="position"> <span className="glyphicon glyphicon-map-marker"></span> {element["gouvernorat"]}</p>


                                        </Link>
                                        <div >
                                            <br />
                                            <h5>{element["titre"]}</h5>
                                            <div className="description">
                                                <p>{element["description"]}</p>
                                            </div>
                                            <hr />
                                            <ul>
                                                <li>
                                                    <img src={element["photo"][0]} alt="" />
                                                </li>
                                                <li>
                                                    <span> tunisie immobilier</span> <br></br>
                                                    <span> {this.fromStringToDate(element["datecreation"])}</span>
                                                </li>
                                            </ul>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </Item>

                            )
                        })}
                    </Carousel>
                ) : (<div> </div>
                )
                }
            </div>

        )
    }
}
