import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

export default function PageNotFound() {
    return (
        <div>
            <Header active="" />

            <div style={{marginTop:"87px"}} > 
                {/* Start Page Heading */}
                <div className="utf-page-heading text-center shadow dark bg-fixed text-light" style={{ backgroundImage: 'url(assets/img/banner/breadcrum.jpg)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Error Page</h1>
                                <div className="utf-breadcrumb-items">
                                    <ul className="breadcrumb">
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">Page</a></li>
                                        <li className="active">Page Note Found</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Heading */}
                {/* Start 404 */}
                <div className="utf-error-page-area text-center default-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <h1>404</h1>
                                <h2>Sorry Page Was Not Found!</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.</p>
                                
                               <Link to="/">  <a className="btn btn-theme effect btn-md"  href="#">Back To Home</a> </Link> </div>
                        </div>
                    </div>
                </div>
                {/* End 404 */}
            </div>
            <footer className="text-center bg-dark text-light">
                <div className="container">
                    <div className="col-md-3">
                        <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
                        <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
                        un
                        contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
                    </div>
                    <div className="col-md-3">
                        <ul>
                            <li id="contact_widget-2" className="widget-container contact_sidebar">
                                <h4>Contact siège à Tunis</h4>
                                <div>
                                    <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                        894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                                    <p> </p>
                                    <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                                </div>
                            </li>
                            <li id="contact_widget-1" className="widget-container contact_sidebar">
                                <h4>Contact siège à Sousse</h4>
                                <div className="contact_sidebar_wrap">
                                    <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                        210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                                    <p />
                                    <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <ul>
                            <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                                <h4>Nos sites</h4>
                                <div className="textwidget">
                                    <ul>
                                        <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                                        <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                                        <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                                        <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                                        </li>
                                        <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                                        <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                                        <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4>Rejoignez-nous !</h4>
                        <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
                        </div>
                        <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
                        </div>
                        <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer2">
                <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
            </div>
        </div>
    )
}
