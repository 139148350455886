import React, { Component } from "react";
import {
    Form,
    Input,
    Button,
    Upload,
    message,
    Row,
    Col,
    Layout,
    Menu,
    Select,
} from "antd";
import {
    UploadOutlined,
    BarChartOutlined,
    CloudOutlined,
    UserOutlined,
    BarsOutlined,
    PlusOutlined
} from "@ant-design/icons";
import _ from 'lodash';
import S3 from "react-aws-s3";
import moment from 'moment';
import Header2 from "./Header4"
import axios from "axios";


import "antd/dist/antd.css";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;

const config = {
    bucketName: "tunisie-immob",
    /*  dirName: 'media', optional */
    region: "eu-west-3",
    accessKeyId: "AKIAXVWFAUBHFUM7UQ24",
    secretAccessKey: "2w/tBnEYOmQgGThMEdS7JiccnMeV0DP4fXF1sYbi",
    /* s3Url: 'https:/your-custom-s3-url.com/',  optional */
};
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const layout2 = {
    wrapperCol: {
        span: 20,
    },
};
const layout3 = {
    wrapperCol: {
        span: 24,
    },
};
const layoutt = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 16,
    },
};

export default class Editfiche extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualUser: "",
            userInformation: {},
            fileList: [],
            imageUrls: [],
            uploading: false,
            ajoutAnnonce: false,
            fiche :{}
        };
    }
    configuration = {
        name: "file",
        multiple: true,
        showUploadList: {
            showDownloadIcon: false,
        },
        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);

            }
        },
        beforeUpload: (file) => {
            this.setState((state) => ({
                fileList: [...state.fileList, file],
            }));
            this.setState({ ajoutAnnonce: true })

            return false;
        },
        showUploadList: {
            showRemoveIcon: false,
        },
    };

    handleLogOut = () => {
        this.props.history.push({
            pathname: '/'
        });
        localStorage.removeItem("id")
        localStorage.removeItem("username")
        localStorage.removeItem("password")
        localStorage.removeItem("email")
    }
    getFiche = (id) => {
        axios.get(`https://tunisieimmob.herokuapp.com/api/fichebyUser/${id}`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    fiche: response.data[0],
                })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const userid = localStorage.getItem("id-gerant");
        this.setState({ actualUser: userid });
        this.getFiche(userid);
    }
    // handleChange = info => {
    //     let fileList = [...info.fileList];
    //     // 1. Limit the number of uploaded files
    //     // Only to show two recent uploaded files, and old ones will be replaced by the new
    //     fileList = fileList.slice(-10);

    //     // 2. Read from response and show file link
    //     fileList = fileList.map(file => {
    //       if (file.response) {
    //         // Component will show file.url as link
    //         file.url = file.response.url;
    //       }
    //       console.log(file)
    //       return file;

    //     });

    //     this.setState({ fileList });
    //   };
    handleUpload2 = async () => {

        const { fileList } = this.state;
        console.log(this.state.fileList);
        this.setState({
            uploading: true,
        });
        for (let i = 0; i < this.state.fileList.length; i++) {
            const file = this.state.fileList[i];
            const x = await this.handleUpload(file);

        }
        this.setState({
            uploading: false,
        });
        this.setState({ ajoutAnnonce: false })



    }
    handleUpload = async (file) => {
        const m = moment();
        let newFileName = m + file.name
        const ReactS3Client = new S3(config);
        await ReactS3Client.uploadFile(file, newFileName)
            .then((data) => {
                this.setState((state) => ({
                    imageUrls: [...state.imageUrls, data.location]
                }))
            })
            .catch((err) => console.log("error " + err));
    };
    handleSubmit = (e) => {
        console.log(e);
        console.log(this.state.imageUrls);
        const m = moment();

        axios
            .post(`https://tunisieimmob.herokuapp.com/api/fiche`, {
                categorie : e.categorie,
                codepostal: e.codepostal,
                description : e.description ,
                email : e.email , 
                facebook : e.facebook, 
                gouvernorat : e.gouvernorat ,
                latitude : "21234558",
                longitude : "215055440",
                mobile : e.mobile ,
                photo : this.state.imageUrls ,
                region : e.region ,
                site : e.site ,
                tel : e.tel ,
                titre : e.titre ,
                type: e.type , 
                emailuser: localStorage.getItem("email-gerant"),
                iduser: this.state.actualUser,
            })
            .then((response) => {
                console.log(response);
                message.success("modification  enregistré avec succès");
                this.props.history.push({
                    pathname: '/mafiche'
                });
            })
            .catch((error) => {
                console.log(error);
            });


        // console.log(this.state.fileList)
        // for (let i = 0; i < this.state.fileList.length; i++) {
        //     this.handleUpload(this.state.fileList[i]);

        // }


        // const name = e.avatar.file.name;

        // ReactS3Client.uploadFile(e.avatar.file, name)
        //     .then((data) => {
        //         console.log( data.location);


        // })
        // .catch((err) => console.log("error " + err));
    };
    render() {
        const { uploading, fileList, ajoutAnnonce } = this.state;
        console.log(this.state.imageUrls); 
          console.log('fiche ' + this.state.fiche);      
        return (
            <div style={{ marginTop: "87px" }}>
                <Header2 handleLogOut={this.handleLogOut} />
                <Layout>
                    <Sider
                        style={{
                            overflow: "auto",
                            height: "100vh",
                            position: "fixed",
                            left: 0,
                        }}
                    >
                        <div className="logo" />
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={["2"]}>
                            <Menu.Item key="1" icon={<UserOutlined />}>
                                <Link to="/distributionprofile" >Profile et Logo</Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<BarsOutlined />}>
                                <Link to="/Editfiche" >Modifier Ma fiche</Link>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<BarsOutlined />}>
                                <Link to="/mafiche" > Ma fiche</Link>
                            </Menu.Item>

                            {/* <Menu.Item key="4" icon={<HeartOutlined />}>
                favoris
              </Menu.Item>
              <Menu.Item key="5" icon={<FileSearchOutlined />}>
                Recherche enregistrées
              </Menu.Item> */}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout" style={{ marginLeft: 200 }}>
                        <Header className="site-layout-background" style={{ padding: 0 }} >
                            <h1 style={{ color: "white" }} >Gestion fiche distributeur </h1>
                            <h4 style={{ color: "white" }}  >Pour avoir une belle fiche, commencer d'abord par ajouter votre logo dans "Profile et Logo", ensuite ajouter votre fiche dans "Ajouter une Fiche"</h4>
                        </Header>
                        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                            <div
                                className="site-layout-background"
                            >
                                <div>
                                    <Form layout="vertical" className="login-form" onFinish={this.handleSubmit}
                                        initialValues={{
                                            'titre': "",
                                            'description': "",
                                            'tel': "",
                                            'mobile':"",
                                            'email':"",
                                            'site':"",
                                            'facebook':"",
                                            'type':"",
                                            'categorie':"",
                                            'gouvernorat':"",
                                            'adresse':"",
                                            'ville':"",
                                            'region':'',
                                            'codepostal':'',
                                        }} >

                                        <br />
                                        <br />
                                        <h3> Modifier Ma fiche</h3>
                                        <hr />
                                        <br />
                                        <Row gutter={24}>
                                            <Col span={4} >
                                                <h4>Description </h4>
                                                <h6>Cette description apparaîtra en premier sous votre fiche. Un bref aperçu, facile à lire par les visiteurs.
</h6> <h6>Remplir soigneusement votre fiche pour avoir le maximum d'information sur votre établissement</h6>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item {...layout}
                                                    label="Titre" name="titre"
                                                >
                                                    <Row>
                                                        <Input/>
                                                    </Row>
                                                </Form.Item>
                                                <Form.Item
                                                    {...layout}
                                                    label="Description"
                                                    name="description"
                                                >
                                                    <TextArea rows={8} />
                                                </Form.Item>
                                                <Form.Item
                                                    {...layout}
                                                    label="Téléphone distributeur"
                                                    name="tel"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...layout}
                                                    label="Téléphone mobile"
                                                    name="mobile"

                                                >
                                                    <Input placeholder="" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...layout}
                                                    label="Adresse mail"
                                                    name="email"

                                                >
                                                    <Input placeholder="" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...layout}
                                                    label="Site internet"
                                                    name="site"

                                                >
                                                    <Input placeholder="" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...layout}
                                                    label="Page facebook"
                                                    name="facebook"

                                                >
                                                    <Input placeholder="" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <br /> <hr />  <br />
                                        <Row gutter={24}>
                                            <Col span={4} >
                                                <h4>Selection type </h4>
                                                <h6>La sélection du type facilitera aux utilisateurs la recherche de votre fiche</h6>
                                            </Col>
                                            <Col span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Type"
                                                    name="type"
                                                >
                                                    <Select>
                                                        <Option value="Appartements">Café</Option>
                                                        <Option value="Bureaux">centre médical</Option>
                                                        <Option value="Commerces">clinique</Option>
                                                        <Option value="Duplexes">Commerce</Option>
                                                        <Option value="Industriel">Fast Food</Option>
                                                        <Option value="Lots">Kiosque</Option>
                                                        <Option value="Studios">Patisserie</Option>
                                                        <Option value="Terrain agricole"> Restaurant</Option>
                                                        <Option value="Terrains">Salon de Thé</Option>
                                                        <Option value="VillaMaison">Santé</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Dans la catégorie"
                                                    name="categorie"

                                                >
                                                    <Select>
                                                        <Option value="Annonces">Distribution</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <br />

                                        <hr />
                                        <br />
                                        <Row gutter={24}>
                                            <Col span={4} >
                                                <h4> Photos fiche </h4>
                                                <h6>Vous pouvez sélectionner plusieurs images à télécharger en même temps.</h6>
                                            </Col>
                                            <Col span={6} >
                                                {/* <Form.Item
                                                {...layout2}
                                                label=" photos"
                                                name="photos"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input title!",
                                                    },
                                                ]}
                                            >
                                            <Upload {...props} fileList={this.state.fileList}>
                                                <Button icon={<UploadOutlined />}>Upload</Button>
                                            </Upload>
                                            </Form.Item> */}
                                                <Form.Item
                                                    {...layout}
                                                    name="photo"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Veuillez saisir au moins une image",
                                                //     },
                                                // ]}

                                                >
                                                    <Upload {...this.configuration}>
                                                        <Button type="primary" icon={<UploadOutlined />}>
                                                            SELECTION PHOTOS
                  </Button>
                                                    </Upload>
                                                    <br></br>
                                                    <Button
                                                        type="primary"
                                                        onClick={this.handleUpload2}
                                                        disabled={fileList.length === 0}
                                                        loading={uploading}
                                                        style={{ marginTop: 16 }}
                                                    >
                                                        {uploading ? 'Uploading' : 'Ajouter les fichier sélectionner'}
                                                    </Button>
                                                </Form.Item>
                                            </Col>


                                        </Row>
                                        <hr />
                                        <br />
                                        <Row gutter={24}>
                                            <Col span={4} >
                                                <h4> Localisation</h4>
                                                <h6>Localisation </h6>

                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...layout2}
                                                    label="Gouvernorat  "
                                                    name="gouvernorat"

                                                >
                                                    <Select>
                                                        <Option value="Ariana">Ariana</Option>
                                                        <Option value="Béja">Béja</Option>
                                                        <Option value="Ben Arous">Ben Arous</Option>
                                                        <Option value="Bizerte">Bizerte</Option>
                                                        <Option value="El kef">El kef</Option>
                                                        <Option value="Gabes">Gabes</Option>
                                                        <Option value="Gafsa">Gafsa</Option>
                                                        <Option value="Jendouba">Jendouba</Option>
                                                        <Option value="Kairouan">Kairouan</Option>
                                                        <Option value="Kasserine">Kasserine</Option>
                                                        <Option value="Kebili">Kebili</Option>
                                                        <Option value="Mahdia">Mahdia</Option>
                                                        <Option value="Mannouba">Mannouba</Option>
                                                        <Option value="Médnine">Médnine</Option>
                                                        <Option value="Monastir">Monastir</Option>
                                                        <Option value="Nabeul">Nabeul</Option>
                                                        <Option value="Seliana">Seliana</Option>
                                                        <Option value="Sfax">Sfax</Option>
                                                        <Option value="Sidi Bouzid">Sidi Bouzid</Option>
                                                        <Option value="Sousse">Sousse</Option>
                                                        <Option value="Tataouine">Tataouine</Option>
                                                        <Option value="Tozeur">Tozeur</Option>
                                                        <Option value="Tunis">Tunis</Option>
                                                        <Option value="Zaghouan">Zaghouan</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col offset={4} span={13}>
                                                <Form.Item
                                                    {...layout2}
                                                    label=" Adresse(rue ou avenue et numéro seulement)"
                                                    name="adresse"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col offset={4} span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Ville ou district "
                                                    name="ville"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Région (quartier, voisinage connu)"
                                                    name="region"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col offset={4} span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Code postal"
                                                    name="codepostal"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
{/* 

                                            <Col offset={4} span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Latitude (for Google Maps)"
                                                    name="latitude"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} >
                                                <Form.Item
                                                    {...layout2}
                                                    label="Longitude (for Google Maps)"
                                                    name="longitude"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col> */}
                                        </Row>
                                        <br></br>
                                        <Row gutter={24}>
                                            <Col offset={7} span={6} >
                                                <Form.Item  {...layout} >
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        disabled={ajoutAnnonce}

                                                    >
                                                        Modifier Ma fiche
                </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <br></br>
                                    </Form>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}
