import React, { Component } from 'react';
import axios from 'axios';


export default class RightBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rightBanner: [],
        }
    }
    getRightBanner = () => {
        axios.get('https://tunisieimmob.herokuapp.com/api/banniereright')
            .then((response) => {
                console.log(response.data);
                this.setState({ rightBanner: response.data })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    componentDidMount() {
        this.getRightBanner();
    }
    render() {
        const client = this.state.rightBanner[Math.floor(Math.random() * this.state.rightBanner.length)];

        return (
            <div>
                <div className="box-sidebar"> 
                <b> Bande d'annonces </b>
                </div>

                <div>
                    {client ? (<a href={client["url"]} target="_blank">
                        <img width={320} height={485} src={client["imagebanniere"]}  />
                    </a>) : (<a href="">
                        <img width={320} height={485} src=""  />
                    </a>)}
                </div>
            </div>
        )
    }
}
