import React, { Component } from 'react'
import Item from "./Item";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import _ from 'lodash';


export default class LogoClient extends Component {
    constructor(props){
        super(props);
        this.state={

            logoClients: [],
        }
    }
    getLogoClients = () => {
        axios.get('https://tunisieimmob.herokuapp.com/api/logobottom')
            .then((response) => {
                console.log(response.data);
                this.setState({ logoClients: response.data })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    componentDidMount(){
        this.getLogoClients();

    }
    render() {
        const breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2, itemsToScroll: 1 },
            { width: 768, itemsToShow: 4 },
            { width: 1200, itemsToShow: 4 }
        ];
        const items = this.state.logoClients;        
        return (
            <div>

            {!_.isEmpty(items) ? (
                <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500} pagination={false}>
                    {items.map((element) => { return <Item> <img src={element["logo"]}></img> </Item> })}
                </Carousel>
            ) : (<div> </div>
            )
            }
        </div>
        )
    }
}
