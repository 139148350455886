
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Modal, Form, Input, Button, message } from 'antd';

import "antd/dist/antd.css";
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 14,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 14,
    },
};
export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,

        }
    }
    getActive = () => {
        console.log(this.props.active)

        const id = this.props.active
        if (id === "annonces") {
            document.getElementById(id).className = "active";
            document.getElementById("test").style.color = "#c70039"


        }
        else
            if (id === "annoncespro") {
                document.getElementById(id).className = " active";
                document.getElementById("test").style.color = "#c70039"


            }
            else {


                if (id) {
                    document.getElementById(id).className = "active";
                }
            }

    }
    componentDidMount() {
        this.getActive();
    }
    onFinish = (values) => {
        axios.post('https://tunisieimmob.herokuapp.com/api/auth/signin', {
            email: values.email,
            password: values.password
        })
            .then((response) => {

                if (response.data.roles[0] === "ROLE_GERANT") {
                    console.log("okkk");
                    localStorage.setItem('id-gerant', response.data.id);
                    localStorage.setItem('username-gerant', response.data.username);
                    localStorage.setItem('password-gerant', response.data.accessToken);
                    localStorage.setItem('email-gerant', response.data.email);
                    this.props.pushdist();

                    // this.props.history.push({
                    //     pathname: '/profile'
                    // });
                }
                else {
                    message.error("l'email ou le mopt de passe sont incorrect")
                }
                this.setState({ isModalVisible: false })
            })
            .catch((error) => {
                console.log(error);
                // if (error.response.data.message === "Email is wrong") {
                //     message.error("l'email est incorrect")
                // }
                // else {
                //     message.error('mot de passe est incorrect')
                // }
            })
    }
    showModal = () => {

        this.setState({ isModalVisible: true })
    };
    handleCancel = () => {
        this.setState({ isModalVisible: false })
    };
    logout = () => {
        this.props.handleLogOut();
    }
    pushprofile = ()=>{
this.props.pushdist();
    }
    render() {
        return (
            <div>
                <header id="home">
                    {/* Start Navigation */}
                    <nav className="navbar navbar-default text-light navbar-fixed navbar-transparent  bootsnav">
                        <div className="navbar-content">
                            {/* Start Header Navigation */}
                            <div className="navbar-header">
                                <a>
                                    <img width={178} height={60} src="assets/img/LOGO-FRANCAIS.png" className="logo" alt="Logo" />
                                </a>
                            </div>
                            {/* End Header Navigation */}
                            <div className="collapse navbar-collapse" id="navbar-menu">
                                <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
                                    <li id="accueil" className="dropdown"> <Link to="/" >Accueil</Link>
                                    </li>
                                    <li id="distribution" className="dropdown"> <Link to="/distribution">Points de distribution</Link>
                                    </li>
                                    <li id="magazines" className="dropdown"> <Link to="/magazines">Magazines</Link>
                                    </li>
                                    <li id="platforme" className="dropdown"> <Link to="/platforme-professionnelle">plateforme-professionnelle</Link>
                                    </li>
                                    <li className="dropdown"> <a id="test" className="dropdown-toggle" data-toggle="dropdown">Annonces</a>
                                        <ul style={{ width : "320px"}} className="dropdown-menu">
                                            <li > <Link id="annonces" to="/liste-des-annonces-toutes">Toutes les annonces</Link></li>

                                            <li  ><Link id="annoncespro" to="/annonces-des-professionnels">Annonces professionnelles</Link></li>
                                            <li  className="header-list" >
                                                <div className="list-link">
                                                    <li className="gouv" > <Link to={'/annonces/Tunis'} > Tunis </Link> </li>
                                                    <li className="gouv" > <Link to={'/annonces/Ariana'} > Ariana </Link></li>
                                                    <li> <Link to={'/annonces/Ben Arous'} > Ben Arous </Link></li>
                                                    <li>  <Link to={'/annonces/Mannouba'} > Mannouba </Link> </li>
                                                    <li> <Link to={'/annonces/Bizerte'} > Bizerte </Link> </li>
                                                    <li>  <Link to={'/annonces/Nabeul'} > Nabeul </Link> </li>
                                                    <li>  <Link to={'/annonces/Jendouba'} > Jendouba </Link> </li>
                                                    <li> <Link to={'/annonces/Béja'} > Béja </Link> </li>
                                                
                                                </div>
                                                <div className="list-link">
                                                <li>  <Link to={'/annonces/El kef'} > El kef </Link> </li>
                                                    <li >   <Link to={'/annonces/Zaghouan'} > Zaghouan </Link> </li>
                                                    <li  >  <Link to={'/annonces/Sousse'} > Sousse</Link> </li>
                                                    <li> <Link to={'/annonces/Monastir'} > Monastir</Link> </li>
                                                    <li><Link to={'/annonces/Mahdia'} > Mahdia</Link> </li>
                                                    <li>  <Link to={'/annonces/Kairouan'} >Kairouan</Link> </li>
                                                    <li>  <Link to={'/annonces/Seliana'} >Seliana</Link> </li>
                                                    <li> <Link to={'/annonces/Kasserine'} >Kasserine</Link> </li>
                                                </div>
                                                <div className="list-link">
                                                    <li> <Link to={'/annonces/Sfax'} > Sfax </Link> </li>
                                                    <li> <Link to={'/annonces/Gabes'} >Gabes</Link> </li>
                                                    <li>  <Link to={'/annonces/Gafsa'} >Gafsa</Link> </li>
                                                    <li> <Link to={'/annonces/Sidi Bouzid'} >Sidi Bouzid</Link> </li>
                                                    <li> <Link to={'/annonces/Tataouine'} >Tataouine</Link> </li>
                                                    <li> <Link to={'/annonces/Médenine'} >Médenine</Link> </li>
                                                    <li>   <Link to={'/annonces/Kebili'} >Kebili</Link> </li>
                                                    <li> <Link to={'/annonces/Tozeur'} >Tozeur</Link> </li>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </li>
                                    <li id="promotion" > <Link to="/promotion-immobiliere-en-tunisie">Nos Promotions</Link></li>
                                    <li id="presentation" className="dropdown"> <Link to="/presentation">Qui Somme-nous ?</Link>
                                    </li>
                                    {localStorage.getItem("email-gerant") ? (
                                        <div className="dropdown pull-right">
                                            <li> <button className="btn btn-theme effect btn-md btn-connecter" onClick={this.pushprofile}> Espace gerant</button>
                                            </li>
                                        </div>
                                    ) : (
                                        <div className="dropdown pull-right">
                                            <li> <button className="btn btn-theme effect btn-md btn-connecter" onClick={this.showModal}>Connecter</button>
                                            </li>
                                        </div>
                                    )}
                                </ul>
                            </div>
                            {/* /.navbar-collapse */}
                        </div>
                    </nav>
                    {/* End Navigation */}
                    <Modal title="Connexion distributeur"
                        visible={this.state.isModalVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        {localStorage.getItem('val1') ? (
                            <Form
                                {...layout}
                                name="basic"
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    label="email"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Mot de passe"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item {...tailLayout} >
                                    <Button type="primary" htmlType="submit">
                                        Se Connecter
                                 </Button>
                                </Form.Item >
                                <Form.Item {...tailLayout} >
                                    <Button type="link" htmlType="button" onClick={this.onSignup}>
                                        créer un compt
                                 </Button>
                                    <Button type="link" htmlType="button">
                                        mot de passe oublié
                                </Button>
                                </Form.Item>
                            </Form>
                        ) : (
                            <Form
                                {...layout}
                                name="basic"
                                onFinish={this.onFinish}

                            >
                                <Form.Item
                                    label="email"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>


                                <Form.Item
                                    label="Mot de passe"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item {...tailLayout} >
                                    <Button type="primary" htmlType="submit">
                                        Se Connecter
                             </Button>
                                </Form.Item >

                            </Form>
                        )}

                    </Modal>
                </header>

            </div>
        )
    }
}
