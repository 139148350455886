import React, { Component } from "react";
import {
    Form,
    Input,
    Button,
    Upload,
    message,
    Row,
    Col,
    Layout,
    Menu,
    Select,
    DatePicker
} from "antd";
import {
    UploadOutlined,
    BarChartOutlined,
    CloudOutlined,
    UserOutlined,
    BarsOutlined,
    PlusOutlined
} from "@ant-design/icons";
import S3 from "react-aws-s3";
import moment from 'moment';
import Header2 from "./Header2"
import axios from "axios";


import "antd/dist/antd.css";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;

const config = {
    bucketName: "tunisie-immob",
    /*  dirName: 'media', optional */
    region: "eu-west-3",
    accessKeyId: "AKIAXVWFAUBHFUM7UQ24",
    secretAccessKey: "2w/tBnEYOmQgGThMEdS7JiccnMeV0DP4fXF1sYbi",
    /* s3Url: 'https:/your-custom-s3-url.com/',  optional */
};
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const layout2 = {
    wrapperCol: {
        span: 20,
    },
};
const layout3 = {
    wrapperCol: {
        span: 24,
    },
};
const layoutt = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 16,
    },
};

export default class Addannonce extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualUser: "",
            userInformation: {},
            fileList: [],
            imageUrls: [],
            uploading: false,
            ajoutAnnonce: false
        };
    }
    configuration = {
        name: "file",
        multiple: true,
        showUploadList: {
            showDownloadIcon: false,
        },
        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);

            }
        },
        beforeUpload: (file) => {
            this.setState((state) => ({
                fileList: [...state.fileList, file],
            }));
            this.setState({ ajoutAnnonce: true })

            return false;
        },
        showUploadList: {
            showRemoveIcon: false,
        },
    };

    handleLogOut = () => {
        this.props.history.push({
            pathname: '/'
        });
        localStorage.removeItem("id")
        localStorage.removeItem("username")
        localStorage.removeItem("password")
        localStorage.removeItem("email")
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const userid = localStorage.getItem("id");
        this.setState({ actualUser: userid });
    }
    // handleChange = info => {
    //     let fileList = [...info.fileList];
    //     // 1. Limit the number of uploaded files
    //     // Only to show two recent uploaded files, and old ones will be replaced by the new
    //     fileList = fileList.slice(-10);

    //     // 2. Read from response and show file link
    //     fileList = fileList.map(file => {
    //       if (file.response) {
    //         // Component will show file.url as link
    //         file.url = file.response.url;
    //       }
    //       console.log(file)
    //       return file;

    //     });

    //     this.setState({ fileList });
    //   };
    handleUpload2 = async () => {

        const { fileList } = this.state;
        console.log(this.state.fileList);
        this.setState({
            uploading: true,
        });
        for (let i = 0; i < this.state.fileList.length; i++) {
            const file = this.state.fileList[i];
            const x = await this.handleUpload(file);

        }
        this.setState({
            uploading: false,
        });
        this.setState({ ajoutAnnonce: false })



    }
    handleUpload = async (file) => {
        const m = moment();
        let newFileName = m + file.name
        const ReactS3Client = new S3(config);
        await ReactS3Client.uploadFile(file, newFileName)
            .then((data) => {
                this.setState((state) => ({
                    imageUrls: [...state.imageUrls, data.location]
                }))


            })
            .catch((err) => console.log("error " + err));
    };
    handleSubmit = (e) => {
        console.log(e);
        console.log(this.state.imageUrls);
        const m = moment();

        axios
            .post(`https://tunisieimmob.herokuapp.com/api/annonces/${this.state.actualUser}`, {
                adresse: e.adresse,
                anneeconstruction: e.anneeconstruction,
                apresprix: e.apresprix,
                avantprix: e.avantprix,
                categorie: e.categorie,
                chambre: e.chambre,
                codepostal: e.codepostal,
                constexterne: e.constexterne,
                datecreation: m,
                description: e.description,
                dispoapartir: e.dispoapartir,
                etat: "en attente",
                etatannonce: "disponible",
                garage: e.garage,
                gouvernorat: e.gouvernorat,
                latitude: e.latitude,
                longitude: e.longitude,
                note: e.note,
                photo: this.state.imageUrls.length ? this.state.imageUrls : ["https://tunisie-immob.s3-eu-west-3.amazonaws.com/annonce-default.jpg"],
                piece: e.piece,
                prix: e.prix,
                region: e.region,
                salledebain: e.salledebain,
                soussol: e.soussol,
                superficiegarage: e.superficiegarage,
                superficiehab: e.superficiehab,
                superficieterr: e.superficieterr,
                telephone: e.telephone,
                titre: e.titre,
                toiture: e.toiture,
                type: e.type,
                typeannonce: "normale",
                ville: e.ville
            }, { id: this.state.actualUser })
            .then((response) => {
                console.log(response);
                message.success("annonce  enregistré avec succès");
                this.props.history.push({
                    pathname: '/mesannonces'
                });
            })
            .catch((error) => {
                console.log(error);
            });


        // console.log(this.state.fileList)
        // for (let i = 0; i < this.state.fileList.length; i++) {
        //     this.handleUpload(this.state.fileList[i]);

        // }


        // const name = e.avatar.file.name;

        // ReactS3Client.uploadFile(e.avatar.file, name)
        //     .then((data) => {
        //         console.log( data.location);


        // })
        // .catch((err) => console.log("error " + err));
    };
    render() {
        const { uploading, fileList, ajoutAnnonce } = this.state;
        console.log(this.state.imageUrls)
        return (
            <div style={{ marginTop: "87px" }}>
                <Header2 handleLogOut={this.handleLogOut} />
                <Layout>
                    <Sider
                        style={{
                            overflow: "auto",
                            height: "100vh",
                            position: "fixed",
                            left: 0,
                        }}
                    >
                        <div className="logo" />
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={["3"]}>
                            <Menu.Item key="1" icon={<UserOutlined />}>
                                <Link to="/profile" >Mon Profile</Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<BarsOutlined />}>
                                <Link to="/mesannonces" >Mes annonces</Link>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<PlusOutlined />}>
                                <Link to="/ajout-annonce" >Ajouter une annonce</Link>
                            </Menu.Item>
                            {/* <Menu.Item key="4" icon={<BarChartOutlined />}>
                                favoris
              </Menu.Item>
                            <Menu.Item key="5" icon={<CloudOutlined />}>
                                Recherche enregistrées
              </Menu.Item> */}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout" style={{ marginLeft: 200 }}>
                        <Header className="site-layout-background" style={{ padding: 0 }} />
                        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                            <div
                                className="site-layout-background"
                            >
                                <Form layout="vertical" className="login-form" onFinish={this.handleSubmit}>
                                    <br />
                                    <br />
                                    <h3>Ajouter Une Annonce </h3>
                                    <hr />
                                    <br />
                                    <Row gutter={24}>
                                        <Col span={4} >
                                            <h4>Description </h4>
                                            <h6>Cette description apparaîtra en premier sous votre annonce. Un bref aperçu, facile à lire par les visiteurs.</h6>
                                        </Col>

                                        <Col span={14}>
                                            <Form.Item {...layout} label="Titre" name="titre" rules={[
                                                {
                                                    required: true,
                                                    message: "Veuillez saisir le titre",
                                                },
                                            ]}>
                                                <Row>
                                                    <Input />
                                                </Row>
                                            </Form.Item>
                                            <Form.Item
                                                {...layout}
                                                label="Description"
                                                name="description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir le Description de l'annonce",
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={8} />
                                            </Form.Item>
                                            <Form.Item
                                                {...layout}
                                                label="Téléphone annonceur"
                                                name="telephone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir le numéro de téléphone",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="télephone" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout}
                                                label="Description"
                                            >
                                                <TextArea rows={8} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br /> <hr />  <br />
                                    <Row gutter={24}>
                                        <Col span={4} >
                                            <h4>Prix </h4>
                                            <h6>Ajouter un prix facilitera la recherche des utilisateurs pour votre annonce dans les résultats de recherche.</h6>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Prix en TND (Chiffres seulement)"
                                                name="prix"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir le prix",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Après le prix (ex: par Mois)"
                                                name="apresprix"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Avant le prix (ex: A partir de..)"
                                                name="avantprix"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <br />
                                    <Row gutter={24}>
                                        <Col span={4} >
                                            <h4>Selection type et catégorie  </h4>
                                            <h6>La sélection du type et la catégorie facilitera aux utilisateurs la recherche des annonces par type et par catégorie.</h6>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Type"
                                                name="type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir la catégorie d'annonce",
                                                    },
                                                ]}

                                            >
                                                <Select>
                                                    <Option value="Appartements">Appartements</Option>
                                                    <Option value="Bureaux">Bureaux</Option>
                                                    <Option value="Commerces">Commerces</Option>
                                                    <Option value="Duplexes">Duplexes</Option>
                                                    <Option value="Industriel">Industriel</Option>
                                                    <Option value="Lots">Lots</Option>
                                                    <Option value="Studios">Studios</Option>
                                                    <Option value="Terrain agricole">Terrain agricole</Option>
                                                    <Option value="Terrains">Terrains</Option>
                                                    <Option value="VillaMaison">Villas / maisons</Option>

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Dans la catégorie"
                                                name="categorie"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir la catégorie d'annonce",
                                                    },
                                                ]}
                                            >
                                                <Select>
                                                    <Option value="Annonces">Annonces</Option>
                                                    <Option value="Location">Location</Option>
                                                    <Option value="Location vacances">Location vacances</Option>
                                                    <Option value="Vente">Vente</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <br />
                                    <Row gutter={24}>
                                        <Col span={4} >
                                            <h4>Détailer votre annonce
 </h4>
                                            <h6>Détaillez un peu plus de caractéristiques sur votre annonce.
</h6>
                                        </Col>


                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Superficie habitable m² (Nombres uniquement)"
                                                name="superficiehab"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Superficie du terrain m²  (Nombres uniquement)"
                                                name="superficieterr"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={24}>


                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Pièces (Nombres uniquement)"
                                                name="piece"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Chambres (Nombres uniquement)"
                                                name="chambre"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={24}>


                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Salles de bain (Nombres uniquement)"
                                                name="salledebain"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Garages (text)"
                                                name="garage"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={24}>
                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Année de construction (numerique)"
                                                name="anneeconstruction"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Superficie du garage (text)"
                                                name="superficiegarage"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={24}>
                                        <Col offset={4} span={6} >
                                            <Form.Item

                                                label="Disponible à partir"
                                                name="dispoapartir"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir la date",
                                                    },
                                                ]}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="sous-sol (text)"
                                                name="soussol"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={24}>


                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Construction externe (text)"
                                                name="constexterne"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Toiture (text)"
                                                name="toiture"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={24}>
                                        <Col offset={4} span={13}>
                                            <Form.Item
                                                {...layout2}
                                                label=" Notes du propriétaire / agent (non visible sur la page)"
                                                name="note"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <br />
                                    <Row gutter={24}>
                                        <Col span={4} >
                                            <h4>Photos annonce</h4>
                                            <h6>Vous pouvez sélectionner plusieurs images à télécharger en même temps.</h6>
                                        </Col>
                                        <Col span={6} >
                                            {/* <Form.Item
                                                {...layout2}
                                                label=" photos"
                                                name="photos"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input title!",
                                                    },
                                                ]}
                                            >
                                            <Upload {...props} fileList={this.state.fileList}>
                                                <Button icon={<UploadOutlined />}>Upload</Button>
                                            </Upload>
                                            </Form.Item> */}
                                            <Form.Item
                                                {...layout}
                                                name="avatar"
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Veuillez saisir au moins une image",
                                            //     },
                                            // ]}

                                            >
                                                <Upload {...this.configuration}>
                                                    <Button type="primary" icon={<UploadOutlined />}>
                                                        SELECTION PHOTOS
                  </Button>
                                                </Upload>
                                                <br></br>
                                                <Button
                                                    type="primary"
                                                    onClick={this.handleUpload2}
                                                    disabled={fileList.length === 0}
                                                    loading={uploading}
                                                    style={{ marginTop: 16 }}
                                                >
                                                    {uploading ? 'Uploading' : 'Ajouter les fichier sélectionner'}
                                                </Button>
                                            </Form.Item>
                                        </Col>


                                    </Row>
                                    <hr />
                                    <br />
                                    <Row gutter={24}>
                                        <Col span={4} >
                                            <h4> Localisation</h4>
                                            <h6>Localisation </h6>

                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                {...layout2}
                                                label="Gouvernorat  "
                                                name="gouvernorat"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Veuillez saisir le gouvernorat",
                                                    },
                                                ]}

                                            >
                                                <Select>
                                                    <Option value="Ariana">Ariana</Option>
                                                    <Option value="Béja">Béja</Option>
                                                    <Option value="Ben Arous">Ben Arous</Option>
                                                    <Option value="Bizerte">Bizerte</Option>
                                                    <Option value="El kef">El kef</Option>
                                                    <Option value="Gabes">Gabes</Option>
                                                    <Option value="Gafsa">Gafsa</Option>
                                                    <Option value="Jendouba">Jendouba</Option>
                                                    <Option value="Kairouan">Kairouan</Option>
                                                    <Option value="Kasserine">Kasserine</Option>
                                                    <Option value="Kebili">Kebili</Option>
                                                    <Option value="Mahdia">Mahdia</Option>
                                                    <Option value="Mannouba">Mannouba</Option>
                                                    <Option value="Médnine">Médnine</Option>
                                                    <Option value="Monastir">Monastir</Option>
                                                    <Option value="Nabeul">Nabeul</Option>
                                                    <Option value="Seliana">Seliana</Option>
                                                    <Option value="Sfax">Sfax</Option>
                                                    <Option value="Sidi Bouzid">Sidi Bouzid</Option>
                                                    <Option value="Sousse">Sousse</Option>
                                                    <Option value="Tataouine">Tataouine</Option>
                                                    <Option value="Tozeur">Tozeur</Option>
                                                    <Option value="Tunis">Tunis</Option>
                                                    <Option value="Zaghouan">Zaghouan</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col offset={4} span={13}>
                                            <Form.Item
                                                {...layout2}
                                                label=" Adresse(rue ou avenue et numéro seulement)"
                                                name="adresse"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>


                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Ville ou district "
                                                name="ville"

                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Région (quartier, voisinage connu)"
                                                name="region"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={24}>


                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Code postal"
                                                name="codepostal"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>


                                        <Col offset={4} span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Latitude (for Google Maps)"
                                                name="latitude"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} >
                                            <Form.Item
                                                {...layout2}
                                                label="Longitude (for Google Maps)"
                                                name="longitude"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row gutter={24}>
                                        <Col offset={7} span={6} >
                                            <Form.Item  {...layout} >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={ajoutAnnonce}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Veuillez saisir le type de l'annonce ",
                                                        },
                                                    ]}
                                                >
                                                    Ajouter Annonce
                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}
