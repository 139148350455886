import React, { Component } from 'react'
import axios from "axios";
export default class Banner extends Component {
    constructor(props){
        super(props);
        this.state={
            banner: [],
        }
    }
    getBanner = () => {
        axios.get('https://tunisieimmob.herokuapp.com/api/bannieretop')
            .then((response) => {
                console.log(response.data);
                this.setState({ banner: response.data })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    componentDidMount(){
        this.getBanner();  
    }
    render() {
        return (
            <div>
                 <div className="banniere1">
                    <div id="carousel-example-generic0" className="carousel slide" data-ride="carousel">

                        {/* Indicators */}
                        <ol className="carousel-indicators">
                            {this.state.banner.map((element, key) => {
                                if (key === 0) {
                                    return (
                                        <li data-target="#carousel-example-generic0" data-slide-to={0} className="active" />
                                    );
                                }
                                else {
                                    return (
                                        <li data-target="#carousel-example-generic0" data-slide-to={key} />
                                    );
                                }
                            })}
                   
                        </ol>
          
                        <div className="carousel-inner" role="listbox">
                            {this.state.banner.map((element, key) => {
                                if (key === 0) {
                                    return (
                                        <div className="item active">
                                            <img height="100%" width="100%" src={element["imagebanniere"]} alt="..." />
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div className="item">
                                            <img height="100%" width="100%" src={element["imagebanniere"]} alt="..." />
                                        </div>
                                    );
                                }

                            })}
                        </div>
                        {/* Controls */}
                        <a className="left carousel-control" href="#carousel-example-generic0" role="button" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />
                        </a>
                        <a className="right carousel-control" href="#carousel-example-generic0" role="button" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
                        </a>
                    </div>
                </div>
              
            </div>
        )
    }
}
