import React, { Component } from 'react'
import Header from './Header';
import Banner from './Banner';
import LogoClient from './LogoClient';
import Recherch from './Recherch';

export default class Platformepro extends Component {
  constructor(props) {
    super(props)
    this.pushProfile = this.pushProfile.bind(this);
    this.handleRecherche = this.handleRecherche.bind(this)
  }
  handleRecherche =(val1 , val2 , val3 )=>{
        
    this.props.history.push({
        pathname: `/Annoncesresult/${val1}/${val2}/${val3}`
    });
}
  pushProfile = () => {
    this.props.history.push({
      pathname: '/ajout-annonce'
    });
  }
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Header active={"platforme"} pushProfile={this.pushProfile} />
        <Banner />
        <div className="bg-gray-2 coutf-services-area default-padding">
          <div className="container">
            <div className="row">
              {/* recherche form*/}
              <Recherch handleRecherche={this.handleRecherche} />
                </div>
          </div>
        </div>
        <div className="container default-padding" >
          <div>
            <img src="https://www.tunisieimmob.net/wp-content/uploads/2020/12/SLOGANT1.jpg" alt="" />
            <div>
              <p style={{ fontSize: '30px', color: '#c70039', lineHeight: '1.5', fontWeight: 'bold', fontFamily: '"Poppins", sans-serif', textAlign: 'center' }}>
                <b>LE PLUS GRAND PORTAIL DE L'IMMOBILIER EN TUNISIE </b></p>
            </div>
            <div style={{ marginLeft: '43px', textAlign: 'left', textJustify: 'inter-word', fontFamily: '"Open Sans", sans-serif', fontSize: '14px', marginRight: '40px' }}>
              <span style={{ fontSize: '18px', color: '#c70039', lineHeight: '1.5', fontWeight: 200, fontFamily: '"Poppins", sans-serif' }}>
                <b> Une application immobiliére,c’est le fer de lance de votre entreprise.
            </b></span>Permettre à vos clients de trouver le bien de leurs rêves : voilà, en quelques mots, l’essence de
          votre
          métier de professionnel de l’immobilier. La base de tout.
          Sauf que, derrière cette définition fort simple, se dissimule tout un monde de complexité. Que ce soit dans
          le domaine de la gestion pure (faire tourner votre petite – ou grande – entreprise) ou qu’il s’agisse des
          stratégies commerciales à développer pour exister aux yeux des prospects et développer votre chiffre
          d’affaires.
          En ce sens, faute d’avoir les bons outils, vous êtes condamné à travailler avec des bouts de ficelle. Le «
          système D », ce n’est pas si mal ; ça va même pendant un temps, celui qu’il faut pour lancer votre projet et
          rentrer vos premiers contrats. Toutefois, vous avez rapidement besoin d’autre chose, d’un outil à la mesure
          de vos ambitions. Les musiciens déploient leurs talents sur des instruments de qualité ; les architectes
          s’adossent aux meilleurs matériaux. Et vous, vous avez besoin d’une application immobiliére de haut vol.
          Enfin de compte, vous êtes à la recherche de solutions commercial et marketing pour augmenter vos contrats
          immobiliers, vos ventes immobilières, votre portefeuille-clients, avec l’ère du digital, découvrez 5 bonnes
          raisons de faire votre propre application.
          <ol type={1}>
                <li><b> Pour augmenter votre visibilité sur Internet et les Réseaux Sociaux. </b></li>
                <li><b> Pour attirer davantage de visiteurs sur votre site Internet.</b></li>
                <li><b> Pour convertir les visiteurs en prospects puis en clients.</b></li>
                <li><b> Pour mesurer le resultat de vos actions marketing.</b></li>
                <li><b> Recevez votre newsletter personnalisée.</b></li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container" >
          <div className="utf-services-area">
            {/* table */}
            <div style={{ margin: 'auto' }} className="default-padding">
              <table style={{ margin: 'auto' }}>
                <tbody>
                  <tr>
                    <td style={{ border: 'none', borderCollapse: 'collapse', padding: 0, margin: 0, textAlign: 'center' }}>
                      <div style={{ backgroundColor: '#c82c2f', textAlign: 'center !important', fontFamily: '"Open Sans", sans-serif', height: '50px', color: 'white', fontSize: '17px', paddingTop: '15px' }}>
                        <b> Promoteurs Immobiliers </b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: 'none', borderCollapse: 'collapse', padding: 0, margin: 0 }}>
                      {/* slide 1*/}
                      <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="false">
                        {/* Wrapper for slides */}
                        <div className="carousel-inner" style={{ backgroundColor: '#c0bdbd !important', paddingLeft: '110px', paddingRight: '48px', height: '200px', marginTop: '0px !important' }} role="listbox ">
                          <div className="item active" style={{ marginTop: '12px' }}>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.follaimmobiliere.com/">
                                  <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/5.png" />
                                </a>
                                            <div className="app-name">  Folla Immobilere</div>
                                          <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial,sans-serif' }}>
                                <a href="http://cosmosimmo.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/1-1.png" /></a>
                        COSMOS Immobilière
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/2.jpg" /></a>
                        Diar Esperanza
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/3.png" /></a>
                        Immobilière Doura
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.ettaamir.com/fr/apercu.php"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/4.png" />
                                </a>SPI ETTAAMIR
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://gammarth-immobiliere.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/6.png" />
                                </a>Gammarth Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.greencityimmobiliere.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/7.png" /></a>
                        GREEN CITY Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/8.png" /></a>
                                <div className="app-name">   SPI L'aigle </div>
                       
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/9.png" /></a>
                        La Dous Immobilière
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                          </div>
                          <div className="item" style={{ marginTop: '12px' }}>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/10.png" />
                         <div className="app-name" >
                         S.M.L.I
                        </div>
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/12.png" />
                        L'Immobilière de l'Avenue
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.chaabane-immo.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/13.png" /></a>
                        Immobilière chaabane
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.allianceimmob.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/14.png" /></a>
                        groupe Alliance
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/15.png" />
                        tiba immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/9.png" /></a>
                        La Dous Immobilière
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/17.png" />
                         <div className="app-name" > 
                         Build Up
                        </div>
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.immobiliereechaarika.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/18.png" /></a>
                        Immobiliere Echaarika
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.essoukna.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/19.png" />
                                </a>
                        Essoukna
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                          </div>
                          {/* Controls */}
                          <a className="left carousel-control" style={{ backgroundImage: 'none !important', color: 'white', width: '52px', height: '200px', top: '0%', marginTop: '0px' }} role="button" href="#carousel-example-generic" data-slide="prev">
                            <i style={{ marginTop: '85px', fontSize: '30px' }} className="fa fa-chevron-left" />
                          </a>
                          <a className="right carousel-control" style={{ backgroundImage: 'none !important', color: 'white', width: '52px', height: '200px', top: '0%', marginTop: '0px' }} role="button" href="#carousel-example-generic" data-slide="next">
                            <i style={{ marginTop: '85px', fontSize: '30px' }} className="fa fa-chevron-right" />
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: 'none', borderCollapse: 'collapse', padding: 0, margin: 0, textAlign: 'center' }}>
                      <div style={{ backgroundColor: '#c82c2f', textAlign: 'center !important', fontFamily: '"Open Sans", sans-serif', height: '50px', color: 'white', fontSize: '17px', paddingTop: '15px' }}>
                        <b> Société de matériaux de construction </b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: 'none', borderCollapse: 'collapse', padding: 0, margin: 0 }}>
                      {/* slide 1*/}
                      <div id="carousel-example-generic3" className="carousel slide" data-ride="carousel" data-interval="false">
                        {/* Wrapper for slides */}
                        <div className="carousel-inner" style={{ backgroundColor: '#c0bdbd !important', paddingLeft: '110px', paddingRight: '48px', height: '200px', marginTop: '0px !important' }} role="listbox ">
                          <div className="item " style={{ marginTop: '12px' }}>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://wood-plast.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/ABS.png" /></a>
                         <div className="app-name" >
                         Wood Plast
                        </div>
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.abc-abdennadher.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/ABS-.png" /></a>
                        abc Abdennadher
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/alchimy.png" />
                        V Design &amp; Solution
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/allum-de-sahel.png" />
                        aluminium du sahel
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/CPA.png" />
                       <div className="app-name" >
                       CPA </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/asso-vernici.png" /></a>
                       <div className="app-name" >
                       Asso Vernici </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/HORIZON-BETON.png" /></a>
                       <div className="app-name" >
                       Horizon Béton </div> 
                        <span style={{ textAlign: 'center' }}>Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/sodecor.png" />
                       <div className="app-name" >
                       SoDecor  </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/inara-copie.png" /></a>
                       <div className="app-name" >
                       Beton Safe </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                          </div>
                          <div className="item active" style={{ marginTop: '12px' }}>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.adirautomatisme.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/Logo-Adir-copie.png" /></a>
                        Adir Automatisme
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/Logo-Comptoir-Alum-Sahel-copie.png" /></a>
                      <div className="app-name" >
                      C A S 
                        </div>
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.ikit.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/Logo-Delta-Cuisine.png" /></a>
                        IKIT Cuisine &amp; Dressing
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://idealcuisine.com.tn/"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/logo-ideal-cuisin.png" /></a>
                       <div className="app-name"> 
                       Ideal cuisine </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.tunitaly.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/LOGO-TUNITALY.png" /></a>
                       <div className="app-name"> 
                       Tunitaly </div>
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/MAINCOR.png" />
                       <div className="app-name"> 
                       Maincor </div>
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.lemiroirdusahel.com/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/MIROIRE-DE-SAHEL-copie.png" />
                                </a> Le Miroir Du Sahel
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/probat.png" /></a>
                        Probat Menuiserie
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/sines.png" /></a>
                         <div className="app-name" >
                       
                         Sines
                 
                         </div>
                         
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                          </div>
                          {/* Controls */}
                          <a className="left carousel-control" style={{ backgroundImage: 'none !important', color: 'white', width: '52px', height: '200px', top: '0%', marginTop: '0px' }} role="button" href="#carousel-example-generic3" data-slide="prev">
                            <i style={{ marginTop: '85px', fontSize: '30px' }} className="fa fa-chevron-left" />
                          </a>
                          <a className="right carousel-control" style={{ backgroundColor: 'none !important', color: 'white', width: '52px', height: '200px', top: '0%', marginTop: '0px' }} role="button" href="#carousel-example-generic3" data-slide="next">
                            <i style={{ marginTop: '85px', fontSize: '30px' }} className="fa fa-chevron-right" />
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: 'none', borderCollapse: 'collapse', padding: 0, margin: 0, textAlign: 'center !important' }}>
                      <div style={{ backgroundColor: '#c82c2f', textAlign: 'center', fontFamily: '"Open Sans", sans-serif', height: '50px', color: 'white', fontSize: '17px', paddingTop: '15px' }}>
                        <b> Agences Immobilières </b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: 'none', borderCollapse: 'collapse', padding: 0, margin: 0 }}>
                      {/* slide 2*/}
                      <div id="carousel-example-generic2" className="carousel slide" data-ride="carousel" data-interval="false">
                        {/* Wrapper for slides 2*/}
                        <div className="carousel-inner" style={{ backgroundColor: '#c0bdbd !important', paddingLeft: '110px', paddingRight: '48px', height: '200px', marginTop: '0px !important' }} role="listbox">
                          <div className="item active" style={{ marginTop: '12px' }}>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="#"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/1.png" /></a>
                        Agence Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.dar-immo.net/index.php?option=com_jea"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/2.png" /></a>
                      <div className="app-name">
                      Dar Immo </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.menzili.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/3-1.png" /></a>
                       <div className="app-name">
                       Menzili.tn  </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.expertimmo.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/4-1.png" /></a>
                        L'expert Immobilier
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/5-1.png" />
                       <div className="app-name">
                       Agence Immo  </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.immo-expert.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/7-1.png" /></a>
                       <div className="app-name">
                       Immo expert </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/8-1.png" />
                        L'agence Immobilière
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/9-1.png" />
                        Agence Etoile Immobilier
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/10-1.png" />
                        Safti Immobilier
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                          </div>
                          <div className="item" style={{ marginTop: '12px' }}>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/11.png" />
                        AA Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/12-1.png" />
                        agence Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/13-1.png" />
                        Gala Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://fr.foncia.com/"> <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/14-1.png" /></a>
                         <div className="app-name" >
                         Foncia  </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/15-1.png" />
                        Leaders Immobilier
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/4-1.png" />
                        L'expert Immobilier
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/1.png" />
                        Agence Immobiliere
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="http://www.dar-immo.net/index.php?option=com_jea"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/2.png" /></a>
                       <div className="app-name" >
                       Dar Immo </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                            <div style={{ margin: '5px 10px', float: 'left', lineHeight: '13px', fontSize: '10px' }}>
                              <div style={{ height: '170px', width: '80px', backgroundColor: 'white', padding: '7px', boxShadow: '0px 0px 3px 3px rgba(0, 0, 2, .3)', fontFamily: 'arial, sans-serif' }}>
                                <a href="https://www.menzili.tn/"><img style={{ border: '3px solid #bbbab8', borderRadius: '5px', marginTop: '0px !important', width: '65px', height: '65px' }} src="assets/img/3-1.png" /></a>
                        <div className="app-name">
                        Menzili.tn </div> 
                        <span style={{ textAlign: 'center' }}> Telecharger</span><img style={{ marginBottom: '3px' }} src="assets/img/appstore.jpg" alt="" width height /><img style={{ marginBottom: '3px' }} src="assets/img/googleplay.jpg" alt="" width height />
                              </div>
                            </div>
                          </div>
                          {/* Controls */}
                          <a className="left carousel-control" style={{ backgroundImage: 'none !important', color: 'white', width: '52px', height: '200px', top: '0%', marginTop: '0px' }} role="button" href="#carousel-example-generic2" data-slide="prev">
                            <i style={{ marginTop: '85px', fontSize: '30px' }} className="fa fa-chevron-left" />
                          </a>
                          <a className="right carousel-control" style={{ backgroundImage: 'none !important', color: 'white', width: '52px', height: '200px', top: '0%', marginTop: '0px' }} role="button" href="#carousel-example-generic2" data-slide="next">
                            <i style={{ marginTop: '85px', fontSize: '30px' }} className="fa fa-chevron-right" />
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      
          <div className="utf-services-area bg-gray  default-padding">
            <div className="container">
              <div style={{ paddingTop: '5px' }} className="utf-site-heading">
                <h4>GUIDE DE L'IMMOBILIER <span> EN TUNISIE </span></h4>
              </div>
              <div className="col-md-3">
                <div style={{ backgroundColor: 'white' }} className="effect-left-swipe-img ">
                  <div className="item">
                    <a href="blog-detail.html">
                      <img src="assets/img/economie-tun.jpg"  /></a>
                    <p style={{ textAlign: 'center', fontSize: '18px', color: 'black', fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', background: 'linear-gradient(#c4c2c2, #e0e1eb)', height: '35px', paddingTop: '5px' }}>
                      Economie de la Tunisie
                </p>
                <p>
                    </p>
                    <div className="guide">L’économie de la Tunisie est inscrite
                    dans un
                    processus de réformes économiques et de libéralisation
                    depuis
                  1986 après trois décennies de dirigisme et de participations de l’État à l’économie de la tunisie .</div>
                    <button className="btn-plus"> <b> Savoir Plus </b></button>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div style={{ backgroundColor: 'white' }} className="effect-left-swipe-img">
                  <div className="item">
                    <a href="blog-detail.html">
                      <img src="assets/img/commerce-electronique-tunisie.jpg"  /></a>
                    <p style={{ textAlign: 'center', fontSize: '18px', color: 'black', fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', background: 'linear-gradient(#c4c2c2, #e0e1eb)', height: '35px', paddingTop: '5px' }}>
                      E-commerce en tunisie
                </p><p>
                    </p><div className="guide">L’e-commerce en tunisie est inscrite
                    dans un
                    processus de réformes économiques et de libéralisation
                    depuis
                  1986 après trois décennies de dirigisme et de participations de l’État à l’économie de la tunisie .</div>
                    <button className="btn-plus"> <b> Savoir Plus </b></button>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div style={{ backgroundColor: 'white' }} className="effect-left-swipe-img">
                  <div className="item">
                    <a href="blog-detail.html">
                      <img src="assets/img/argus-immob.jpg"  /></a>
                    <p style={{ textAlign: 'center', fontSize: '18px', color: 'black', fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', background: 'linear-gradient(#c4c2c2, #e0e1eb)', height: '35px', paddingTop: '5px' }}>
                      L'argus immobilier
                </p><p>
                    </p><div className="guide">En Tunisie, le prix du m² dans le « neuf
                    » a
                    considérablement augmenté ces dix dernières années. La
                    hausse
                    a dépassé 50 % dans certaines zones du Grand Tunis (Marsa, Berges du Lac, Ennasr) et, dans les villes
                  balnéaires… </div>
                    <button className="btn-plus"> <b> Savoir Plus </b></button>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '20px' }} className="col-md-3">
                <div style={{ backgroundColor: 'white' }} className="effect-left-swipe-img">
                  <div className="item">
                    <a href="blog-detail.html">
                      <img src="assets/img/avis-expert.jpg"  /></a>
                    <p style={{ textAlign: 'center', fontSize: '18px', color: 'black', fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', background: 'linear-gradient(#c4c2c2, #e0e1eb)', height: '35px', paddingTop: '5px' }}>
                      Avis des experts
                </p><p>
                    </p><div className="guide">La Tunisie est de plus en plus
                    considérée comme un
                    pays exemplaire pour l’investissement immobilier.
                    Elle
                    est devenue un marché immobilier attractif</div>
                    <button className="btn-plus"> <b> Savoir Plus </b></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
     

        <LogoClient />

        <footer className="text-center bg-dark text-light">
          <div className="container">
            <div className="col-md-3">
              <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
              <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
              un
              contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="contact_widget-2" className="widget-container contact_sidebar">
                  <h4>Contact siège à Tunis</h4>
                  <div>
                    <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                        894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                    <p> </p>
                    <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                  </div>
                </li>
                <li id="contact_widget-1" className="widget-container contact_sidebar">
                  <h4>Contact siège à Sousse</h4>
                  <div className="contact_sidebar_wrap">
                    <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                        210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                    <p />
                    <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                  <h4>Nos sites</h4>
                  <div className="textwidget">
                    <ul>
                      <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                      </li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Rejoignez-nous !</h4>
              <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
              </div>
              <div className="col-md-3">
                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer2">
          <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. Designed by Tunisie Immobilier </p>
        </div>
      </div>
    )
  }
}
