import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from './Header';
import Banner from './Banner';
import LogoClient from './LogoClient';
import RightBanner from './RightBannerIntern';
import Magazine from './Magazine';
import axios from 'axios';
import ReactPaginate from 'react-paginate';




export class Promo extends Component {
    render() {
        console.log(this.props.promos)
        return (
            <div>
                {this.props.promos.map((element) => {
                    return (
                        <div className="col-md-4 equal-height promotion">
                            <div className=" item">
                                <div className=" promotion-page thumb">
                                    <span>{element['titre']}</span>
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe className="embed-responsive-item" src={element['video']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

class Promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            offset: 0,
            orgpromos: [],
            perPage: 12,
            currentPage: 0
        }
        this.pushProfile = this.pushProfile.bind(this)

    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    loadMoreData() {
        const data = this.state.orgpromos;

        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            promos: slice
        })

    }
    pushProfile = () => {
        this.props.history.push({
            pathname: '/ajout-annonce'
        });
    }

    getPromos = () => {
        axios.get('https://tunisieimmob.herokuapp.com/api/promotion')
            .then((response) => {
                console.log(response.data);
                var data = response.data;

                var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgpromos: response.data,
                    promos: slice,
                });
            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getPromos();

    }

    render() {
        return (
            <div>
                <Header active={"promotion"} pushProfile={this.pushProfile} />
                <Banner />
                <div className="utf-services-area bg-gray default-padding">
                    <div className="container">
                        <div className="row">
                            <div className=" promotion-page col-md-8">
                                <div className="utf-site-heading">
                                    <h4> PROMOTION IMMOBILIÈRE <span> EN TUNISIE</span></h4>
                                </div>
                                <p>
                                    La rubrique promotion immobilière réunit toutes les entreprises dont l’activité principale est
                                    la réalisation d’opérations immobilières : logement, vente des résidences de tourisme et des
                                    constructions destinées aux loisirs, bureaux, locaux commerciaux…etc.
                                </p>
                                <p>
                                    Tunisie immobilier TV vous permet de développer et commercialiser vos nouveaux projets dans le
                                    secteur immobilier en Tunisie et à l’étranger. Suivez toutes nos reportages sur notre site dont
                                    l’objectif primordial est d’offrir une meilleure visibilité des investisseurs et des promoteurs
                                    immobiliers tunisiens et étrangers.
                                </p>
                                <br />




                                <div className=" row promotion-page2  utf-blog-items">
                                    <Promo promos={this.state.promos} />

                                </div>

                                <div className="row pagination">
                                    <ReactPaginate

                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                </div>

                            </div>
                            <div className="col-md-4">
                                <ul>
                                    <li>
                                        <Magazine />
                                    </li>
                                    <li>
                                        <div className="pf-item video development  right-info">
                                            <br />
                                            <RightBanner />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="pf-item video development  right-info">
                                            <br />
                                            <div className="box-sidebar">
                                                <b> REJOIGNEZ-NOUS !</b>
                                                <a> <img src="assets/img/capture3.JPG" /></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* start logo-clients */}
                <LogoClient />
                {/* Start Footer*/}
                <footer className="text-center bg-dark text-light">
                    <div className="container">
                        <div className="col-md-3">
                            <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
                            <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
                                un
                                contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                                    d'infos ici</a></p>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li id="contact_widget-2" className="widget-container contact_sidebar">
                                    <h4>Contact siège à Tunis</h4>
                                    <div>
                                        <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                                            saoudite
                                        </p>
                                        <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                                            894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                                        <p> </p>
                                        <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                                    </div>
                                </li>
                                <li id="contact_widget-1" className="widget-container contact_sidebar">
                                    <h4>Contact siège à Sousse</h4>
                                    <div className="contact_sidebar_wrap">
                                        <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                                            4000
                                            Sousse
                                        </p>
                                        <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                                            210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                                        <p />
                                        <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                                    <h4>Nos sites</h4>
                                    <div className="textwidget">
                                        <ul>
                                            <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                                            <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                                            </li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                                            <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h4>Rejoignez-nous !</h4>
                            <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
                            </div>
                            <div className="col-md-3">
                                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
                            </div>
                            <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
                            </div>
                            <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer2">
                    <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
                </div>

            </div >
        )
    }
}
export default Promotion;