import React, { Component } from "react";
import { Form, Input, Button, Upload, Image, Row, Col, Layout, Menu, } from "antd";
import {
  UploadOutlined,
  UserOutlined,
  PlusOutlined,
  HeartOutlined,
  BarsOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
import S3 from "react-aws-s3";
import moment from 'moment';

import axios from "axios";
import _ from 'lodash';
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import Header2 from "./Header4"
const { Header, Content, Footer, Sider } = Layout;
const config = {
  bucketName: "tunisie-immob",
  /*  dirName: 'media', optional */
  region: "eu-west-3",
  accessKeyId: "AKIAXVWFAUBHFUM7UQ24",
  secretAccessKey: "2w/tBnEYOmQgGThMEdS7JiccnMeV0DP4fXF1sYbi",
  /* s3Url: 'https:/your-custom-s3-url.com/',  optional */
};
const ReactS3Client = new S3(config);
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};
const layoutt = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};
const layout2 = {
  labelCol: {
    span: 14,
  },
  wrapperCol: {
    span: 16,
  }
};

const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 16,
  },
};

export default class Profiledist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualUser: "",
      userInformation: {},
      imageUrl: ""
    };
  }
  forceReloder = () => {
    console.log('force reload', this.state.actualUser);
    this.getUserInformation(this.state.actualUser);
  }
  getUserInformation = (id) => {
    axios
      .get(`https://tunisieimmob.herokuapp.com/api/users/${id}`)
      .then((response) => {
        console.log(response.data);
        this.setState({ userInformation: response.data, imageUrl: response.data.logo });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleLogOut = () => {
    this.props.history.push({
      pathname: '/distribution'
    });


    localStorage.removeItem("id-gerant");
    localStorage.removeItem("username-gerant");
    localStorage.removeItem("email-gerant");


  }
  getFiche =()=>{
    
  }
  componentDidMount() {

        const userid = localStorage.getItem("id-gerant");

        this.setState({ userpassword: localStorage.getItem("password-gerant") });
        this.setState({ useremail: localStorage.getItem("email-gerant") });
        this.setState({ actualUser: userid });
        this.getUserInformation(userid);
        this.getFiche(userid);
  }

  handleSubmit = (e) => {

    console.log(e);
    if (e.logo) {
      const m = moment();
      let newFileName = m + e.logo.name
      ReactS3Client
        .uploadFile(e.logo.file, newFileName)
        .then(data => {
          localStorage.setItem('imageUrl', data.location);
          console.log(data.location);
        }
        )
        .catch(err => console.log("error " + err))
    }
    const id = this.state.actualUser;
    const imageUrl = localStorage.getItem('imageUrl');
    console.log(imageUrl);
    axios
      .put(`https://tunisieimmob.herokuapp.com/api/users/${id}`, {
        aboutme: '',
        act: "",
        adresse:'',
        email: e.email,
        facebook: '',
        fax: '',
        gouvernorat: '',
        gsm: e.telephone,
        id: id,
        instagram: '',
        linkedin: '',
        logo: imageUrl,
        nom: e.nom,
        ordre: 0,
        password: this.state.userpassword,
        position: '',
        prenom: e.prenom,
        skype: '',
        statut: "",
        tel: e.mobile,
        twitter:'',
        username: '',
        ville: '',
        web: '',
      })
      .then((response) => {
        this.forceReloder();

        console.log(response);
        localStorage.removeItem('imageUrl')
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleSubmitPassword = (e) => {

    console.log(e)

  };

  render() {
    const props = {
      name: 'file',
      multiple: false,
      showUploadList: {
        showDownloadIcon: false,
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [file]
        }))
        return false;
      },
    };
    console.log(this.state.userInformation.email)
    return (
      <div style={{ marginTop: "87px" }}>
        <Header2 handleLogOut={this.handleLogOut} />

        <Layout>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
          >
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/distributionprofile" >Profile et Logo</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<BarsOutlined />}>
                <Link to="/Editfiche" >Modifier Ma fiche</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<BarsOutlined />}>
                                <Link to="/mafiche" > Ma fiche</Link>
                            </Menu.Item>
             
              {/* <Menu.Item key="4" icon={<HeartOutlined />}>
                favoris
              </Menu.Item>
              <Menu.Item key="5" icon={<FileSearchOutlined />}>
                Recherche enregistrées
              </Menu.Item> */}
            </Menu>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200 }}>
            <Header className="site-layout-background" style={{ padding: 0 }} >
              <h1 style={{ color: "white" }} >Gestion fiche distributeur </h1>
              <h4 style={{ color: "white" }}  >Pour avoir une belle fiche, commencer d'abord par ajouter votre logo dans "Profile et Logo", ensuite ajouter votre fiche dans "Ajouter une Fiche"</h4>
            </Header>
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div
                className="site-layout-background"
              >

                <Form layout="vertical" className="login-form" onFinish={this.handleSubmit}
                initialValues={{
                  'nom': this.state.userInformation.nom,
                  'Prenom':this.state.userInformation.prenom,
                  'telephone':this.state.userInformation.tel ,
                  'mobile':this.state.userInformation.gsm,
              
                  'email':this.state.userInformation.email,
               
                }}
                >
                  <br />
                  <br />

                  <Row>
                    <Col span={4} >
                      <h3>Logo </h3>
                      <h5> Téléchargez le logo de votre 
                        établissement ici</h5>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...layout} label="photo">
                        <Image width={200} height={200} src={this.state.imageUrl} />
                      </Form.Item>
                      <Form.Item
                        {...layout}
                        name="logo"
                      >
                        <Upload {...props}>
                          <Button icon={<UploadOutlined />}>
                            télécharger le logo
                        </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={4} span={6}>
                      <Form.Item
                      {...layout} label="Prénom"
                        name="Prenom"
                        >
                        <Input
                          // value="old password"
                        />
                      </Form.Item>
                      <Form.Item
                      {...layout} label="Nom"
                        name="nom"
                         >
                        <Input
                          // value="old password"
                        />
                      </Form.Item>
                      <Form.Item
                      {...layout} label="email"
                        name="email"
                        >
                        <Input  disabled={true}
                          // value="old password"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                     
                      <Form.Item
                      {...layout} label="Téléphone"
                        name="telephone"
                        >
                        <Input
                          // value="old password"
                        />
                      </Form.Item>
                      <Form.Item
                      {...layout} label="Mobile"
                        name="mobile"
                         >
                        <Input
                          // value="old password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>


                  <Form.Item {...tailLayout} >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Mettre à jour
  </Button>
                  </Form.Item>
                </Form>
                <hr />
                <br />
                {/* <Form layout="vertical" onSubmit={this.handleSubmitPassword} className="login-form">
                  <Row>
                    <Col span={4} >
                      <h4>Modifier Mot de passe </h4>
                      <h6>*Après avoir changé le mot de passe, vous devrez vous connecter à nouveau.</h6>
                    </Col>
                    <Col span={6}>
                      <Form.Item  {...layout} label="Old Password"
                        name="oldpassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]} >
                        <Input
                          value="old password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={4} span={6}>
                      <Form.Item {...layout} label="new password" name="newpassword" rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}  >
                        <Input value="New password" />
                      </Form.Item>
                    </Col>
                    <Col span={6}  >
                      <Form.Item  {...layout} label="Confirm Password"
                        name="confirmnewpassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]} >
                        <Input
                          placeholder="Confirm Password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                     Valider nouveau mot de passe 
                   </Button>
                  </Form.Item>
                  <hr />
                  <br />
                </Form>
              */}
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Ant Design ©2018 Created by Ant UED
            </Footer>
          </Layout>
        </Layout>

      </div>

    );
  }
}
