import React, { Component } from "react";
import "antd/dist/antd.css";

const layout = {
  wrapperCol: {
    span: 22,
  },
};

export default class Recherch extends Component {

  handlesubmit = (e) => {
    e.preventDefault();
    console.log(e.target[0].value);
    console.log(e.target[1].value);
    console.log(e.target[2].value);
    
    this.props.handleRecherche( e.target[0].value, e.target[1].value,e.target[2].value);
  };
  onFinish = (values) => {
    console.log("okk");
    console.log("Success:", values);

    this.props.handleRecherche(values.ville, values.categorie, values.type);
    // this.props.history.push({
    //     pathname: `/Annoncesresult/${values.ville}/${values.categorie}/${values.type}`
    // });
  };
  render() {
    return (
      <div>
        <div className="utf-site-heading">
          <h4>
            Recherche Par <span>Catégorie</span>
          </h4>
        </div>
        <div class="col-md-12">
          <div class="item">
            <div class="content">
              <div class="info">
                <div class="comments-area">
                  <div class="comments-form">
                    <form onSubmit={this.handlesubmit} class="contact-comments">
                      <div class="row">
                        <div class="col-md-3 col-sm-6">
                          <div class="form-group">
                            {/* <input name="name" class="form-control" placeholder="Name" type="text"></input> */}
                            <select
                              placeholder="Toues villes"
                              name="ville"
                              class="form-control"
                            >
                              <option value="Ariana">Ariana</option>
                              <option value="Béja">Béja</option>
                              <option value="Ben Arous">Ben Arous</option>
                              <option value="Bizerte">Bizerte</option>
                              <option value="El Kef">El Kef</option>
                              <option value="Gabes">Gabes</option>
                              <option value="Gafsa">Gafsa</option>
                              <option value="Jendouba">Jendouba</option>
                              <option value="Kairouan">Kairouan</option>
                              <option value="Kasserine">Kasserine</option>
                              <option value="Kebili">Kebili</option>
                              <option value="Mahdia">Mahdia</option>
                              <option value="Mannouba">Mannouba</option>
                              <option value="Médenine">Médenine</option>
                              <option value="Monastir">Monastir</option>
                              <option value="Nabeul">Nabeul</option>
                              <option value="Seliana">Seliana</option>
                              <option value="Sfax">Sfax</option>
                              <option value="Sidi Bouzid">Sidi Bouzid</option>
                              <option value="Sousse">Sousse</option>
                              <option value="Tataouine">Tataouine</option>
                              <option value="Tozeur">Tozeur</option>
                              <option value="Tunis">Tunis</option>
                              <option value="Zaghouan">Zaghouan</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                          <div class="form-group">
                            <select
                              class="form-control"
                              name="categorie"
                              placeholder="toutes Catégories"
                            >
                              <option value="Annonces">Annonces</option>
                              <option value="Location">Location</option>
                              <option value="Location Vacances">
                                Location Vacances
                              </option>
                              <option value="Vente">Vente</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                          <div class="form-group">
                            <select
                              class="form-control"
                              name="type"
                              placeholder="tous types"
                            >
                              <option value="Appartements">Appartements</option>
                              <option value="Bureaux">Bureaux</option>
                              <option value="Commerces">Commerces</option>
                              <option value="Duplexes">Duplexes</option>
                              <option value="Industriel">Industriel</option>
                              <option value="Lots">Lots</option>
                              <option value="Studios">Studios</option>
                              <option value="Terrain agricole">
                                Terrain agricole
                              </option>
                              <option value="Terrains">Terrains</option>
                              <option value="VillaMaison">
                                Villas / maisons
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                          <div class="form-group full-width submit">
                            <button
                              style={{
                                marginTop: "0px",
                                backgroundColor: "#c70039",
                              }}
                              type="submit"
                              
                            >
                              Chercher{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
