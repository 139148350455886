import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Form, Input, Button, message } from "antd";

import "antd/dist/antd.css";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 14,
  },
};
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isModalVisible1: false,
    };
  }
  getActive = () => {
    console.log(this.props.active);

    const id = this.props.active;
    if (id === "annonces") {
      document.getElementById(id).className = "active";
      document.getElementById("test").style.color = "#c70039";
    } else if (id === "annoncespro") {
      document.getElementById(id).className = " active";
      document.getElementById("test").style.color = "#c70039";
    } else {
      if (id) {
        document.getElementById(id).className = "active";
      }
    }
  };
  componentDidMount() {
    this.getActive();
  }
  onFinish = (values) => {
    axios
      .post("https://tunisieimmob.herokuapp.com/api/auth/signin", {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        if (response.data.roles[0] === "ROLE_USERS") {
          localStorage.setItem("id", response.data.id);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("password", response.data.accessToken);
          localStorage.setItem("email", response.data.email);
          this.props.pushProfile();
        } else {
          message.error("l'email ou le mopt de passe sont incorrect");
        }
        this.setState({ isModalVisible: false });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.message === "Email is wrong") {
          message.error("l'email est incorrect");
        } else {
          message.error("mot de passe est incorrect");
        }
      });
  };
  onFinish1 = (values) => {
    console.log(values);
    axios
      .post(`https://tunisieimmob.herokuapp.com/api/auth/signup`, {
        username: values.username,
        email: values.email,
        password: values.password,
        role: ["users"],
        // role:{"id":2 , "name":"ROLE_ADMIN"}
      })
      .then((response) => {
        console.log(response);

        if (response.data === "Registration Successful") {
          message.success("L'utilisateur s'est enregistré avec succès");
          //     localStorage.setItem('id',response.data.id);
          // localStorage.setItem('username',response.data.username);
          // localStorage.setItem('password',response.data.accessToken);
          // localStorage.setItem('email',response.data.email);
          // this.props.history.push({
          //     pathname: '/profile'
          //   });
          localStorage.setItem("val1", values.email);
          localStorage.setItem("val2", values.password);
          this.setState({ isModalVisible1: false });
          setTimeout(() => {
            this.setState({ isModalVisible: true });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.response);

        if (error.response.data.message === "Username is already taken!") {
          message.error("email exist déja");
        }
        if (error.response.data.message === "Email is already taken!") {
          message.error("Cet email est déjà pris!");
        }
      });
  };
  onSignup = () => {
    this.setState({ isModalVisible: false });

    this.setState({ isModalVisible1: true });
  };
  showModal = () => {
    this.setState({ isModalVisible1: false });

    this.setState({ isModalVisible: true });
  };
  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };
  handleCancel1 = () => {
    this.setState({ isModalVisible1: false });
  };
  render() {
    return (
      <div>
        <header id="home">
          {/* Start Navigation */}
          <nav className="navbar navbar-default text-light navbar-fixed navbar-transparent  bootsnav">
            <div className="container">
              <div className="navbar-header">
                <button
                  type="button"
                  class="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-menu"
                >
                  {" "}
                  <i class="fa fa-bars"></i>{" "}
                </button>
                <a className="navbar-brand">
                  <img
                    width={178}
                    height={60}
                    src="assets/img/LOGO-FRANCAIS.png"
                    className="logo"
                    alt="Logo"
                  />
                </a>
              </div>
              {/* End Header Navigation */}
              <div className="collapse navbar-collapse" id="navbar-menu">
                <ul
                  className="nav navbar-nav navbar-right"
                  data-in="#"
                  data-out="#"
                >
                  <li id="accueil">
                    {" "}
                    <Link to="/">Accueil</Link>
                  </li>
                  <li id="distribution">
                    {" "}
                    <Link to="/distribution">Points de distribution</Link>
                  </li>
                  <li id="magazines">
                    {" "}
                    <Link to="/magazines">Magazines</Link>
                  </li>
                  <li id="platforme">
                    {" "}
                    <Link to="/platforme-professionnelle">
                      plateforme-professionnelle
                    </Link>
                  </li>
                  <li className="dropdown">
                    {" "}
                    <a
                      id="test"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Annonces
                    </a>
                    <ul style={{ width: "320px" }} className="dropdown-menu">
                      <li>
                        {" "}
                        <Link id="annonces" to="/liste-des-annonces-toutes">
                          Toutes les annonces
                        </Link>
                      </li>

                      <li>
                        <Link
                          id="annoncespro"
                          to="/annonces-des-professionnels"
                        >
                          Annonces professionnelles
                        </Link>
                      </li>
                      <li className="dropdown">
                        <a className="dropdown-toggle " data-toggle="dropdown">
                          nord
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                          <Link to={"/annonces/Tunis"}> Tunis </Link>
                          </li>
                          <li>
                            <Link to={"/annonces/Ariana"}> Ariana </Link>
                          </li>
                          <li>
                            <Link to={"/annonces/Ben Arous"}> Ben Arous </Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Bizerte"}> Bizerte </Link>
                          </li>
                          <li>
                              <Link to={"/annonces/Nabeul"}> Nabeul </Link>
                          </li>
                          <li>
                              <Link to={"/annonces/Mannouba"}>
                              {" "}
                              Mannouba{" "}
                            </Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Jendouba"}>
                              {" "}
                              Jendouba{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to={"/annonces/Jendouba"}>
                              {" "}
                              Jendouba{" "}
                            </Link>
                          </li>
                          <li>
                              <Link to={"/annonces/Béja"}> Béja </Link>
                          </li>
                          <li>
                              <Link to={"/annonces/El kef"}> El kef </Link>
                          </li>
                          <li>
                              <Link to={"/annonces/Zaghouan"}>
                              {" "}
                              Zaghouan{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown">
                        <a
                         className="dropdown-toggle " data-toggle="dropdown">
                          centre
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                             <Link to={"/annonces/Sousse"}> Sousse</Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Mahdia"}> Mahdia</Link>
                          </li>
                          <li>
                            <Link to={"/annonces/Kairouan"}>Kairouan</Link>
                          </li>
                          <li>
                              <Link to={"/annonces/Monastir"}>
                              {" "}
                              Monastir
                            </Link>
                          </li>
                          <li>
                            <Link to={"/annonces/Seliana"}>Seliana</Link>
                          </li>
                         
                          <li>
                             <Link to={"/annonces/Kasserine"}>
                              Kasserine
                            </Link>
                          </li>
                          
                          

                        </ul>
                      </li>
                     
                      <li className="dropdown">
                        <a
                         className="dropdown-toggle " data-toggle="dropdown">
                          Sud
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <Link to={"/annonces/Sfax"}> Sfax </Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Gabes"}>Gabes</Link>
                          </li>
                          <li>
                           <Link to={"/annonces/Gafsa"}>Gafsa</Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Médenine"}>Médenine</Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Sidi Bouzid"}>
                              Sidi Bouzid
                            </Link>
                          </li>
                         
                          <li>
                             <Link to={"/annonces/Tataouine"}>
                              Tataouine
                            </Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Kebili"}>Kebili</Link>
                          </li>
                          <li>
                             <Link to={"/annonces/Tozeur"}>Tozeur</Link>
                          </li>
                          
                          

                        </ul>
                      </li>
                    
                    </ul>
                  </li>
                  <li id="promotion">
                    {" "}
                    <Link to="/promotion-immobiliere-en-tunisie">
                      Nos Promotions
                    </Link>
                  </li>
                  <li id="presentation">
                    {" "}
                    <Link to="/presentation">Qui Somme-nous ?</Link>
                  </li>
                  {localStorage.getItem("email") ? (
                    <li>
                      {" "}
                      <button
                        className="btn btn-theme effect btn-md btn-connecter"
                        onClick={this.props.pushProfile}
                      >
                        Mon Profile
                      </button>
                    </li>
                  ) : (
                    <li>
                      {" "}
                      <button
                        className="btn btn-theme effect btn-md btn-connecter"
                        onClick={this.showModal}
                      >
                        Connecter
                      </button>
                    </li>
                  )}
                </ul>
              </div>
              {/* /.navbar-collapse */}
            </div>
          </nav>
          {/* End Navigation */}
          <Modal
            title="Connxion"
            visible={this.state.isModalVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            {localStorage.getItem("val1") ? (
              <Form
                {...layout}
                name="basic"
                onFinish={this.onFinish}
                initialValues={{
                  email: localStorage.getItem("val1"),
                  password: localStorage.getItem("val2"),
                }}
              >
                <Form.Item
                  label="email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Se Connecter
                  </Button>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="link" htmlType="button" onClick={this.onSignup}>
                    créer un compt
                  </Button>
                  <Button type="link" htmlType="button">
                    mot de passe oublié
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form {...layout} name="basic" onFinish={this.onFinish}>
                <Form.Item
                  label="email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Se Connecter
                  </Button>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="link" htmlType="button" onClick={this.onSignup}>
                    créer un compt
                  </Button>
                  <Button type="link" htmlType="button">
                    mot de passe oublié
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Modal>

          <Modal
            title="Inscription"
            visible={this.state.isModalVisible1}
            footer={null}
            onCancel={this.handleCancel1}
          >
            <Form {...layout} name="basic" onFinish={this.onFinish1}>
              <Form.Item
                label="nom de l'utilisateur"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir un nom d'utilisateur!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "L'entrée n'est pas valide E-mail",
                  },
                  {
                    required: true,
                    message: "Veuillez saisir votre email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="répéter Mot de passe "
                name="password2"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Enregistrer
                </Button>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="link" htmlType="button" onClick={this.showModal}>
                  Se Connecter
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </header>
      </div>
    );
  }
}
