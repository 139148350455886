import axios from 'axios';
import React, { Component } from 'react';
import { Form, Input, Button ,message} from "antd";
import Header from './Header';
import Banner from './Banner';
import Magazine from './Magazine';
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};
const layoutt = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 24,
  },
};
class Aboutus extends Component {
  constructor (props){
    super(props)
    this.pushProfile = this.pushProfile.bind(this)
  }

  formRef = React.createRef();
  pushProfile = () => {
    this.props.history.push({
        pathname: '/ajout-annonce'
    });
}

  handleSubmit = (e) => {
    console.log(e)
    axios.post('https://tunisieimmob.herokuapp.com/api/contact', {
      email: e.email,
      message: e.message,
      nom: e.nom,
      sujet: e.sujet
    })
      .then((response) => {
        message.success('votre message à été envoyé')
      })
      .catch((error) => {
        console.log(error)
      })
      this.formRef.current.resetFields();
  }
  render() {
    return (
      <div>
        <Header active={"presentation"} pushProfile={this.pushProfile}/>
        <Banner />
        <div className="container">
          <div className="row">
            <div className="box-slogant">
              <div className="col-md-4">
                <img src="assets/img/SLOGANT2-LOGO-.png" alt="" />
              </div>
              <div className="col-md-4" />
              <div className="col-md-4"><img width="200%" src="assets/img/SLOGANT2-LOGO-5.png" alt="" /></div>
            </div>
          </div>
        </div>
        <div className="default-padding">
          <div className="container">
            <div className="row">
              <div className=" about-page col-md-8">
                <div className=" no-padding utf-site-heading">
                  <h4> <span> Notre histoire</span></h4>
                </div>
                <p>Tunisie Immobilier est l’ambition d’un jeune tunisien de formation architecte résidant dans la région de
                Sousse, qui a donné naissance au journal à« Le Réflex Immobilier»(Tunisie Immobilier).</p>
                <p>C’est la première revue gratuite d’annonces immobilières en Tunisie, avec un contenu 100% composé
                d’offres
                immobilières de vente et location de biens immobiliers (maisons, appartements et fonds de commerce).</p>
                <p>Depuis janvier 2005, « Le Réflex Immobilier» vient combler un vide informationnel dans le secteur pour
                les
                professionnels du produit immobilier ainsi qu’aux promoteurs immobiliers qui désirent communiquer avec des
                conditions plus favorables.</p>
                <p>En 2008, Suite à l’ouverture sur l’étranger et pour donner une dimension internationale à cette revue et
                spécialement dans les événements internationaux, il a décidé de changer le nom du « Le Réflex Immobilier»
                par celui de « TUNISIE IMMOBILIER».</p>
                <p>Ce dernier se présente comme un magazine mensuel gratuit de publicité distribué gratuitement, par
                courrier
                et sur Internet. Notre magazine leader en Tunisie connait chaque mois un essor considérable et nos
                partenaires ainsi que nos abonnés ne cessent de s’accroitre et nous faire confiance.</p>
                <p>Aujourd’hui à sa 186 ème parution sortira le 15 Novembre 2020, la revue a bien maintenu une régularité de
                parution mensuelle chaque 15 du mois et éditée en plus de 50 000 exemplaire de 44 pages couleurs distribué
                dans plus de 160 points dans toute la Tunisie : Grand Tunis et banlieue, la ville de Sousse et ses
                environs,
                Monastir, Mahdia, Hammamet, Nabeul, Kairouan, Sfax et Bizerte… (Cafés et cafétérias, centres commerciaux,
                commerces de proximité, agences de voyage, hôtellerie, restaurants, etc…!) et afin de maximiser
                l’efficacité, d’améliorer la portée et de produire des ventes Tunisie immobiler distribue également dans
                les
                deux ferries ,de notre partenaire La Compagnie Tunisienne de Navigation (CTN) ,TANIT et CARTHAGE .</p>
                <p>Pour vendre, acheter, louer, échanger ou prospecter notre magazine sera votre support idéal pour
                promouvoir
                et mettre en valeur votre produit.</p>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>
                    <div>
                      <Magazine />
                    </div>
                  </li>
                  <li>
                    <div className="pf-item video development  right-info">
                      <br />
                      <div className="box-sidebar">
                        <b> REJOIGNEZ-NOUS !</b>
                        <a> <img src="assets/img/capture3.JPG"  /></a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray default-padding">
          <div className="container">
            <div className="row">
              <div className="  about-page col-md-8">
                <div className=" no-padding utf-site-heading">
                  <h4> <span> Contactez Nous</span></h4>
                </div>
                <img alt="" height={60} width={200} src="assets/img/TUNISIE-IMMOB-LOGO-.png" align="left" />
                <p>Que vous recherchiez des biens à vendre dans toute la tunisie ou des biens à louer, Tunisie immobilier rend
                la recherche facile. Utilisez notre fonctionnalité unique de cartographie de géolocalisation pour éliminer
                votre villa, maison de ville ou appartement idéal, et contactez directement les propriétaires. Nous vous
                aiderons à trouver la maison de vos rêves en quelques secondes</p>
                <p>Nous offrons à nos clients une mine de connaissances sur tous les aspects de l’achat ou de la vente d’une
                maison. Qu’il vous aide à rechercher la maison de vos rêves, à discuter de nouveaux développements
                immobiliers en Tunisie ou à aider à la vente de votre propriété, nous aimerions avoir l’opportunité d’aider.
                N’hésitez pas à nous contacter pour toute question!</p>
              </div>
              <div className="col-md-4 right-info">

                <Form ref={this.formRef} layout="vertical" className="login-form" onFinish={this.handleSubmit}>
                  <Form.Item {...layout} label="Votre nom" name="nom" rules={[

                    {
                      required: true,
                      message: 'Please input your name!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item {...layout} label="Votre e-mail" name="email" rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item {...layout} label="Sujet" name="sujet" rules={[

                    {
                      required: true,
                      message: 'Please input your message!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item {...layoutt} label="Votre message" name="message" rules={[

                    {
                      required: true,
                      message: 'Please input your message!',
                    },
                  ]}>
                    <TextArea rows={8} />
                  </Form.Item>
                  <Form.Item {...layout} >
                    <Button
                      type="primary"
                      htmlType="submit"
                    >
                      Envoyez
                   </Button>
                  </Form.Item>
                </Form>

                {/* <form>
                <div style={{display: 'none'}}>
                  <input type="hidden" name="_wpcf7" defaultValue={18362} />
                  <input type="hidden" name="_wpcf7_version" defaultValue="5.1.6" />
                  <input type="hidden" name="_wpcf7_locale" defaultValue="fr_FR" />
                  <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f18362-p5707-o1" />
                  <input type="hidden" name="_wpcf7_container_post" defaultValue={5707} />
                </div>
                <p><label> Votre nom (obligatoire)<br />
                    <span className="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" defaultValue size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" /></span> </label></p>
                <p><label> Votre e-mail (obligatoire)<br />
                    <span className="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" defaultValue size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" /></span> </label></p>
                <p><label> Sujet<br />
                    <span className="wpcf7-form-control-wrap your-subject"><input type="text" name="your-subject" defaultValue size={40} className="wpcf7-form-control wpcf7-text" aria-invalid="false" /></span> </label></p>
                <p><
                label> Votre message<br />
                    <span className="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols={43} rows={5} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" defaultValue={""} /></span> </label></p>
                <p>
                <input type="submit" defaultValue="Envoyer" className="btn btn-theme effect btn-md" />
                <span className="ajax-loader" />
                </p>
                <div className="wpcf7-response-output wpcf7-display-none" />
              </form> */}

              </div>
            </div>
          </div>
        </div>
        <footer className="text-center bg-dark text-light">
          <div className="container">
            <div className="col-md-3">
              <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
              <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
              un
              contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="contact_widget-2" className="widget-container contact_sidebar">
                  <h4>Contact siège à Tunis</h4>
                  <div>
                    <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                        894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                    <p> </p>
                    <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                  </div>
                </li>
                <li id="contact_widget-1" className="widget-container contact_sidebar">
                  <h4>Contact siège à Sousse</h4>
                  <div className="contact_sidebar_wrap">
                    <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                        210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                    <p />
                    <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                  <h4>Nos sites</h4>
                  <div className="textwidget">
                    <ul>
                      <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                      </li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Rejoignez-nous !</h4>
              <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
              </div>
              <div className="col-md-3">
                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer2">
          <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
        </div>
      </div>
    )
  }
}
export default Aboutus;