import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from './Header';
import Banner from './Banner';
import LogoClient from './LogoClient';
import Magazine from './Magazine';
import RightBanner from './RightBannerIntern';
import RightBannerInterne from "./RightBannerIntern";


import axios from "axios";
import ReactPaginate from 'react-paginate';
import Recherch from "./Recherch";

export class Annonce extends Component {


    render() {
        return (
            <div>
                {this.props.annonces.map((element) => {
                    return (
                        <tr className="SingleAnnonce">
                            <td style={{ boxShadow: "0 0 2em rgb(196, 196, 196)" }} >
                                <div className="col-md-6" style={{ cssFloat: "left", padding: "10px", width: "360px" }}>
                                    <Link to={`/annonce/${element["id"]}`}>  < img src={element["photo"][0]}
                                         />
                                    </Link>
                                </div>
                                <div className="col-md-6"  >
                                    <h5>
                                        {element["titre"]}
                                    </h5>
                                    <span>Prix :<span style={{ color: "red" }}>{element["prix"]}  TND </span></span>
                                    <br />
                                    <div>
                                    </div>
                                    <p>
                                        {element["description"]}
                                    </p>
                                    <hr style={{
                                    }}
                                    />
                                    <br />
                                    <span className="glyphicon glyphicon-user" aria-hidden="true"
                                    > {element["user"]["username"]}
                                    </span>
                                    <br />
                                    <span className="glyphicon glyphicon-earphone"
                                    >   {element["telephone"]}
                                    </span>

                                </div>
                            </td>
                        </tr>

                    )
                })}
            </div>
        )
    }
}
class Annonces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            annonces: [],
            offset: 0,
            orgAnnonces: [],
            perPage: 6,
            currentPage: 0
        }
        this.pushProfile = this.pushProfile.bind(this);
        this.handleRecherche = this.handleRecherche.bind(this);
    }
    handleRecherche =(val1 , val2 , val3 )=>{
        
        this.props.history.push({
            pathname: `/Annoncesresult/${val1}/${val2}/${val3}`
        });
    }
    pushProfile = () => {
        this.props.history.push({
            pathname: '/ajout-annonce'
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getAnnonces();
    }
    loadMoreData() {
        const data = this.state.orgAnnonces;

        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            annonces: slice
        })
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };
    getAnnonces = (userid) => {
        console.log(userid);
        axios.get(`https://tunisieimmob.herokuapp.com/api/annoncebyetat/valide`)
            .then((response) => {
                console.log(response.data);
                const data = response.data;
                var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);

                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgAnnonces: response.data,
                    annonces: slice,
                })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    render() {
        return (
            <div>
                <Header active={"annonces"} pushProfile={this.pushProfile} />
                <Banner />
                <div className="bg-gray-2 coutf-services-area default-padding">
                    <div className="container">
                        <div className="row">
                            {/* recherche form*/}
                            <div >
                               <Recherch handleRecherche={this.handleRecherche} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="utf-services-area bg-gray default-padding">
                    <div className="container">
                        <div className="row">
                            <div className=" col-md-8">
                                <div className="row" >
                                <div className="utf-site-heading">
                                        <h4> Tous  <span> les annonces</span></h4>
                                    </div>
                                   <div className=" col-md-8 col-sm-2 right-info">
                                     
                                        <h4> {this.state.orgAnnonces.length} trouvés </h4>
                                        <hr class="annonces-line" />
                                        <br></br>
                                    </div>
                                    <table
                                        style={{
                                            borderCollapse: "separate",
                                            borderSpacing: "0px 20px",
                                        }}
                                    >
                                        <tbody>
                                            <Annonce annonces={this.state.annonces} />
                                        </tbody>
                                    </table>
                                </div>


                                <div className="row">
                                    <div className=" pagination" >
                                        <div className="row pagination">
                                            <ReactPaginate
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                    <li>
                                        <Magazine />
                                    </li>
                                    <li>
                                        <div className="pf-item video development  right-info">
                                            <br /><br />
                                            <RightBanner />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="pf-item video development  right-info">
                                            <br /><br />

                                            <RightBannerInterne />
                                        </div>
                                    </li>
                                    <li>
                                        <br /> <br />
                                        <div className="box-sidebar">
                                            <b> REJOIGNEZ-NOUS !</b>
                                            <a> <img src="assets/img/capture3.JPG"  /></a>
                                        </div>
                                    </li>
                                </ul><b>
                                </b>
                            </div><b>
                            </b>
                        </div>

                        <div>
                        </div>
                    </div>
                </div>

                {/* start logo-clients */}
                <LogoClient />
                {/* Start Footer*/}
                <footer className="text-center bg-dark text-light">
                    <div className="container">
                        <div className="col-md-3">
                            <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
                            <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
                            un
                            contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li id="contact_widget-2" className="widget-container contact_sidebar">
                                    <h4>Contact siège à Tunis</h4>
                                    <div>
                                        <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                                        <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                        894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                                        <p> </p>
                                        <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                                    </div>
                                </li>
                                <li id="contact_widget-1" className="widget-container contact_sidebar">
                                    <h4>Contact siège à Sousse</h4>
                                    <div className="contact_sidebar_wrap">
                                        <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                                        <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                        210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                                        <p />
                                        <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                                    <h4>Nos sites</h4>
                                    <div className="textwidget">
                                        <ul>
                                            <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                                            <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                                            </li>
                                            <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                                            <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                                            <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h4>Rejoignez-nous !</h4>
                            <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
                            </div>
                            <div className="col-md-3">
                                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
                            </div>
                            <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
                            </div>
                            <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer2">
                    <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
                </div>
            </div>
        )
    }
}
export default Annonces