import React, { Component } from "react";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  BarsOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import Header2 from "./Header2"
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;

export class Annonce extends Component {


  render() {
    return (
      <div>
        {this.props.annonces.map((element) => {
          return (
            <tr className="SingleAnnonce" >
              <td style={{ boxShadow: "0 0 2em rgb(196, 196, 196)" }} >

                <div className="col-md-6" style={{ cssFloat: "left", padding: "10px", width: "360px" }}>
                  <Link to={`/annonce/${element["id"]}`}>  < img src={element["photo"][0]}
                  />
                  </Link>
                </div>
                <div className="col-md-6" >
                  <h5>
                    {element["titre"]}
                  </h5>
                  <span>Prix :<span style={{ color: "red" }}>{element["prix"]}  TND </span></span>
                  <br />
                  <div>
                  </div>
                  <p>
                    {element["description"]}
                  </p>
                  <hr style={{
                  }}
                  />
                  <br />
                  <span className="glyphicon glyphicon-user" aria-hidden="true"
                  > {element["user"]["username"]}
                  </span>
                  <br />
                  <span className="glyphicon glyphicon-earphone"
                  >   {element["telephone"]}
                  </span>

                </div>
              </td>
            </tr>

          )
        })}
      </div>
    )
  }
}



export default class Mesannonces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annonces: [],
      offset: 0,
      orgAnnonces: [],
      perPage: 4,
      currentPage: 0
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    const userid = localStorage.getItem("id");
    this.setState({ actualUser: userid });
    this.getAnnonces(userid);
  }
  getAnnonces = (userid) => {
    console.log(userid);
    axios.get(`https://tunisieimmob.herokuapp.com/api/mesannonces/${userid}`)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgAnnonces: response.data,
          annonces: slice,
        })
      })
      .catch((error) => {
        console.log(error.response);
      })
  }
  loadMoreData() {
    const data = this.state.orgAnnonces;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      annonces: slice
    })
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };
  handleLogOut = () => {
    this.props.history.push({
      pathname: '/'
    });
    localStorage.removeItem("id")
    localStorage.removeItem("username")
    localStorage.removeItem("password")
    localStorage.removeItem("email")

    //  localStorage.clear();
  }


  render() {
    return (
      <div style={{ marginTop: "87px" }}>
        <Header2 handleLogOut={this.handleLogOut} />

        <Layout>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
          >
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["2"]}>
              <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/profile">Mon Profile</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<BarsOutlined />}>
                <Link to="/mesannonces">Mes annonces</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<PlusOutlined />}>
                <Link to="/ajout-annonce">Ajouter une annonce</Link>
              </Menu.Item>
              {/* <Menu.Item key="4" icon={<HeartOutlined />}>
                favoris
              </Menu.Item>
              <Menu.Item key="5" icon={<FileSearchOutlined />}>
                Recherche enregistrées
              </Menu.Item> */}
            </Menu>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200 }}>
            <Header className="site-layout-background" style={{ padding: 0 }} />
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div className="utf-services-area bg-gray default-padding">
                <div className="row">
                  <div className="col-md-8">
                    <div className="utf-site-heading">
                      <h4>Mes <span> ANNONCES</span></h4>
                    </div>

                    <br></br>
                    <br>
                    </br>
                    <div className=" row col-md-8 col-sm-2 right-info">
                      <h4> {this.state.orgAnnonces.length} trouvés </h4>
                      <hr class="annonces-line" />
                      <br></br>
                    </div>
                    <table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0px 20px",
                      }}
                    >
                      <tbody>

                        <Annonce annonces={this.state.annonces} />

                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <ul className="row">
                    <div className=" pagination" >
                      <div className="row pagination">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"} />
                      </div>
                    </div>
                  </ul>

                </div>

              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Ant Design ©2018 Created by Ant UED
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}
