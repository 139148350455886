import React, { Component } from "react";
import Header from "./Header";
import Banner from "./Banner";
import Magazine from "./Magazine";
import LogoClient from "./LogoClient";
import axios from "axios";
import RightBannerInterne from "./RightBannerIntern";
import _ from "lodash";
import { message } from "antd";
import Recherch from "./Recherch";
import { FacebookShareButton } from "react-share";

var QRCode = require("qrcode.react");
export default class Annonce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AnnonceDetails: {},
    };
    this.pushProfile = this.pushProfile.bind(this);
    this.handleRecherche = this.handleRecherche.bind(this);
  }

  pushProfile = () => {
    this.props.history.push({
      pathname: "/ajout-annonce",
    });
  };
  handleRecherche = (val1, val2, val3) => {
    this.props.history.push({
      pathname: `/Annoncesresult/${val1}/${val2}/${val3}`,
    });
  };

  getAnnonceDetails = (id) => {
    axios
      .get(`https://tunisieimmob.herokuapp.com/api/annonces/${id}`)
      .then((response) => {
        console.log(response);
        if (response.data) {
          console.log("true");
        } else {
          message.error("annonce not found ");
          this.props.history.push({ pathname: "/" });
        }
        console.log("false");

        this.setState({
          AnnonceDetails: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  fromStringToDate(d) {
    var months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];
    const date = new Date(d);
    var elapsed =
      date.getDay() + " " + months[date.getMonth()] + " " + date.getFullYear();
    return elapsed;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const id = this.props.match.params.id;
    this.getAnnonceDetails(id);
    
  }
  render() {
    // active={"annonces"}
    return (
      <div>
        <Header active={"annonces"} pushProfile={this.pushProfile} />
        <Banner />
        <div className="bg-gray-2 coutf-services-area default-padding">
          <div className="container">
            <div className="row">
              {/* recherche form*/}
              <div>
                <Recherch handleRecherche={this.handleRecherche} />
              </div>
            </div>
          </div>
        </div>
        {/*Recherche */}

        <div className="utf-services-area bg-gray default-padding">
          <div className="container">
            <div className="row">
              {!_.isEmpty(this.state.AnnonceDetails) ? (
                <div className="col-md-8">
                  <table style={{ marginTop: "8px" }}>
                    <tbody>
                      <tr>
                        <td style={{ border: "none" }} cellPadding={10}>
                          <span
                            style={{
                              color: "#c70039",
                              fontSize: "20px",
                              marginLeft: "7px",
                            }}
                          >
                            {" "}
                            {this.state.AnnonceDetails["titre"]}{" "}
                          </span>
                          <span
                            style={{
                              color: "cornflowerblue",
                              fontSize: "20px",
                              marginLeft: "7px",
                            }}
                          >
                            {" "}
                            À {this.state.AnnonceDetails["gouvernorat"]}{" "}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "none" }} colSpan={2}>
                          <sapn
                            style={{
                              fontSize: "20px",
                              paddingLeft: "83%",
                              color: "#c70039",
                            }}
                          >
                            {this.state.AnnonceDetails["prix"]} TND{" "}
                          </sapn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ul>
                    <li>
                      {/* carousel */}
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          border: "5px solid gray",
                          margin: "auto",
                        }}
                        id="carousel-example-generic"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        {/* Indicators */}

                        <ol className="carousel-indicators">
                          {this.state.AnnonceDetails["photo"].map(
                            (element, key) => {
                              if (key === 0) {
                                return (
                                  <li
                                    data-target="#carousel-example-generic"
                                    data-slide-to={key}
                                    className="active"
                                  />
                                );
                              } else {
                                return (
                                  <li
                                    data-target="#carousel-example-generic"
                                    data-slide-to={key}
                                  />
                                );
                              }
                            }
                          )}
                        </ol>

                        {/* Wrapper for slides */}

                        <div className="carousel-inner" role="listbox">
                          {this.state.AnnonceDetails["photo"].map(
                            (element, key) => {
                              if (key === 0) {
                                return (
                                  <div className="item active">
                                    <img
                                      style={{ width: "100%", height: "auto" }}
                                      src={element}
                                      alt="..."
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="item">
                                    <img
                                      style={{ width: "100%", height: "auto" }}
                                      src={element}
                                      alt="..."
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                          {/* <div className="item active">
                                                    <img
                                                        style={{ width: "720px", height: "400px" }}
                                                        src={this.state.AnnonceDetails["photo"][0]}
                                                        alt="..."
                                                    />
                                                </div> */}
                        </div>
                        {/* Controls */}
                        <a
                          className="left carousel-control"
                          href="#carousel-example-generic"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            style={{ color: "black" }}
                            className="glyphicon glyphicon-chevron-left"
                            aria-hidden="true"
                          />
                        </a>
                        <a
                          className="right carousel-control"
                          href="#carousel-example-generic"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            style={{ color: "black" }}
                            className="glyphicon glyphicon-chevron-right"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                      <div className="utf-contact-area default-padding">
                        <div className="container">
                          <div className="row">
                            <div
                              style={{ width: "60%" }}
                              className=" address-info left-info"
                            >
                              <ul>
                                <li className="content">
                                  <div className="row">
                                    <div className="col-md-9">
                                      {" "}
                                      {
                                        this.state.AnnonceDetails["description"]
                                      }{" "}
                                    </div>
                                    <div className="col-md-3">
                                      {" "}
                                      <QRCode value={window.location.href} />
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <i className="fas fa-map-marker-alt" />
                                  <p>Adresse </p>
                                </li>
                                <li className="content">
                                  <div className="row">
                                    <div className="col-md-3">
                                      {" "}
                                      <strong>Téléphone :</strong>{" "}
                                      {this.state.AnnonceDetails["telephone"]}
                                    </div>
                                    <div className="col-md-3">
                                      {" "}
                                      <strong>Adresse : </strong>{" "}
                                      {this.state.AnnonceDetails["adresse"]}
                                    </div>
                                    <div className="col-md-3">
                                      <strong> Ville : </strong>{" "}
                                      {this.state.AnnonceDetails["ville"]}{" "}
                                    </div>
                                    <div className="col-md-3">
                                      {" "}
                                      <strong>Gouvernorat : </strong>{" "}
                                      {this.state.AnnonceDetails["gouvernorat"]}
                                    </div>
                                    <div className="col-md-3">
                                      <strong>Code Postal :</strong>{" "}
                                      {this.state.AnnonceDetails["codepostal"]}{" "}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <i className="fas fa-envelope-open" />
                                  <p> Détails </p>
                                </li>
                                <li className="content">
                                  <div className="row">
                                    <div className="col-md-3">
                                      {" "}
                                      <strong>Type : </strong>{" "}
                                      {this.state.AnnonceDetails["type"]}
                                    </div>
                                    <div className="col-md-3">
                                      <strong> Catégorie : </strong>{" "}
                                      {this.state.AnnonceDetails["categorie"]}{" "}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <i className="fas fa-globe" />
                                  <p>caractéristiques </p>
                                </li>
                                <li className="content">
                                  <div className="row">
                                    <div className="col-md-4">
                                      {" "}
                                      <strong>
                                        Superficie habitable m2 :{" "}
                                      </strong>{" "}
                                      {
                                        this.state.AnnonceDetails[
                                          "superficiehab"
                                        ]
                                      }
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Supericie du terrain :</strong>{" "}
                                      {
                                        this.state.AnnonceDetails[
                                          "superficieterr"
                                        ]
                                      }{" "}
                                    </div>
                                    <div className="col-md-4">
                                      {" "}
                                      <strong>Pièces :</strong>{" "}
                                      {this.state.AnnonceDetails["piece"]}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Chambres :</strong>{" "}
                                      {this.state.AnnonceDetails["chambre"]}{" "}
                                    </div>
                                    <div className="col-md-4">
                                      {" "}
                                      <strong>Salles de bain :</strong>{" "}
                                      {this.state.AnnonceDetails["salledebain"]}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Garages :</strong>{" "}
                                      {this.state.AnnonceDetails["garage"]}{" "}
                                    </div>

                                    <div className="col-md-4">
                                      <strong>Année de construction :</strong>{" "}
                                      {this.fromStringToDate(
                                        this.state.AnnonceDetails[
                                          "anneeconstruction"
                                        ]
                                      )}{" "}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Superficie du garage :</strong>{" "}
                                      {
                                        this.state.AnnonceDetails[
                                          "superficiegarage"
                                        ]
                                      }{" "}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Disponible à partir :</strong>
                                      {this.fromStringToDate(
                                        this.state.AnnonceDetails[
                                          "dispoapartir"
                                        ]
                                      )}{" "}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>sous-sol :</strong>{" "}
                                      {this.state.AnnonceDetails["soussol"]}{" "}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Construction externe :</strong>{" "}
                                      {
                                        this.state.AnnonceDetails[
                                          "constexterne"
                                        ]
                                      }{" "}
                                    </div>
                                    <div className="col-md-4">
                                      <strong>Toiture :</strong>{" "}
                                      {this.state.AnnonceDetails["toiture"]}{" "}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <i className="fas fa-globe" />
                                  <p>caractéristiques </p>
                                </li>
                               
                                <li>
                                <i className="fab fa-facebook-f" />
                                <p>
                                <FacebookShareButton url={`https://www.tunisieimmob.net/annonce/${this.props.match.params.id}`} 
                                quote={this.state.AnnonceDetails["titre"]}
                                hashtag="#tunisie_immobilier"
                                >
                                 
                                Partager cette annonce sur facebook
                                  </FacebookShareButton>
                                  </p>
                                </li>
                                
                                
                                
                                
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              ) : (
                <div></div>
              )}

              <div className="col-md-4">
                <Magazine />
                <br></br>
                <RightBannerInterne />
              </div>
            </div>
          </div>
        </div>

        {/* start logo-clients */}
        <LogoClient />
        {/* Start Footer*/}

        <footer className="text-center bg-dark text-light">
          <div className="container">
            <div className="col-md-3">
              <img
                width
                height={110}
                src="assets/img/LOGO-BLANC.png"
                className="logo logo-display"
                alt="Logo"
              />
              <p>
                Depuis 2005, la première revue gratuite d'annonces immobilières
                en Tunisie, avec un contenu 100% composé d'offres immobilières
                de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...).{" "}
                <a href="https://www.tunisieimmob.net/presentation">
                  Plus d'infos ici
                </a>
              </p>
            </div>
            <div className="col-md-3">
              <ul>
                <li
                  id="contact_widget-2"
                  className="widget-container contact_sidebar"
                >
                  <h4>Contact siège à Tunis</h4>
                  <div>
                    <p>
                      <i className="fa fa-building-o" />2 éme étage ,1 rue du
                      royaume d’arabie saoudite
                    </p>
                    <p>
                      <i className="fa fa-phone" />
                      <a href="tel:%28%2B216%29+71+894+500"> 71 894 500</a>{" "}
                      <i className="fa fa-print" />
                      (+216) 71 894 500
                    </p>
                    <p> </p>
                    <p>
                      <i className="fa fa-envelope-o" />
                      <a href="mailto:tunisieimmob@planet.tn">
                        tunisieimmob@planet.tn
                      </a>
                    </p>
                  </div>
                </li>
                <li
                  id="contact_widget-1"
                  className="widget-container contact_sidebar"
                >
                  <h4>Contact siège à Sousse</h4>
                  <div className="contact_sidebar_wrap">
                    <p>
                      <i className="fa fa-building-o" />
                      Rue Mongi Slim immeuble Ferdaws Corniche 4000 Sousse
                    </p>
                    <p>
                      <i className="fa fa-phone" />
                      <a href="tel:%28%2B216%29+73+210+566"> 73 210 566</a>{" "}
                      <i className="fa fa-print" />
                      (+216) 73 213 140
                    </p>
                    <p />
                    <p>
                      <i className="fa fa-envelope-o" />
                      <a href="mailto:contact@tunisieimmob.net">
                        contact@tunisieimmob.net
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li
                  id="black-studio-tinymce-7"
                  className="widget-container widget_black_studio_tinymce"
                >
                  <h4>Nos sites</h4>
                  <div className="textwidget">
                    <ul>
                      <li>
                        <i className="fa fa-link" />{" "}
                        <a href="http://www.tunisieimmob.net/">
                          www.tunisieimmob.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.lereflex-immobilier.net/">
                          www.lereflex-immobilier.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.lereflex-agencesimmo.net/">
                          www.lereflex-agencesimmo.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />{" "}
                        <a href="http://www.lereflex-credits.net/">
                          www.lereflex-credits.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.lereflex-investunisie.net/">
                          www.lereflex-investunisie.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.tunisieimmobiliertv.net/">
                          www.tunisieimmobiliertv.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />{" "}
                        <a href="http://www.lereflex-architecture.net/">
                          www.lereflex-architecture.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Rejoignez-nous !</h4>
              <div className="col-md-3">
                {" "}
                <a href="https://www.facebook.com/www.tunisieimmob.net/">
                  <i className="fab fa-facebook-f" />
                </a>
              </div>
              <div className="col-md-3">
                <a href="https://twitter.com/TvTunisieimmobi">
                  <i className="fab fa-twitter" />
                </a>
              </div>
              <div className="col-md-3">
                {" "}
                <a href="https://www.youtube.com/user/tunisieimmobiliertv">
                  <i className="fab fa-youtube" />
                </a>
              </div>
              <div className="col-md-3">
                {" "}
                <a href="https://www.youtube.com/user/tunisieimmobiliertv">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer2">
          <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
        </div>
      </div>
    );
  }
}
