import { Component } from "react";
import { Link } from "react-router-dom";

import "antd/dist/antd.css";
import ReactPaginate from "react-paginate";
import Header from "./Header";
import Banner from "./Banner";
import LogoClient from "./LogoClient";
import axios from "axios";
import Magazine from "./Magazine";
import RightBanner from "./RightBanner";
import ItemPro from "./ItemPro";
import Recherch from "./Recherch";
import React from "react";
import ReactDOM from "react-dom";
import ReactHlsPlayer from "react-hls-player";

export class Annonce extends Component {
  render() {
    return (
      <div>
        {this.props.annonces.map((element) => {
          return (
            <div
              width="100%"
              className="col-sm-3 col-xs-6 equal-height toutes-annonces"
            >
              <div className="item">
                <div className="thumb">
                  <Link to={`/annonce/${element["id"]}`}>
                    <a href=""></a>
                    <img src={element["photo"][0]} />
                  </Link>
                  <p className="prix"> {element["prix"]} TND</p>
                  <p className="position">
                    {" "}
                    <span className="glyphicon glyphicon-map-marker"></span>{" "}
                    {element["gouvernorat"]}
                  </p>
                  <div>
                    <p>{element["titre"]}</p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

class Accueil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annonces: [],
      offset: 0,
      orgAnnonces: [],
      perPage: 12,
      currentPage: 0,
    };
    this.pushProfile = this.pushProfile.bind(this);
    this.handleRecherche = this.handleRecherche.bind(this);
    this.refresh = this.refresh.bind(this);
  }
  handleRecherche = (val1, val2, val3) => {
    this.props.history.push({
      pathname: `/Annoncesresult/${val1}/${val2}/${val3}`,
    });
  };
  pushProfile = () => {
    this.props.history.push({
      pathname: "/ajout-annonce",
    });
  };
  refresh = () => {
    this.setState({ test: false });
  };
  getAnnonces() {
    axios
      .get("https://tunisieimmob.herokuapp.com/api/annoncebyetat/valide")
      .then((response) => {
        const data = response.data;
        var slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgAnnonces: data,
          annonces: slice,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };
  loadMoreData() {
    const data = this.state.orgAnnonces;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      annonces: slice,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAnnonces();
  }

  getAnnoncesTop = () => {
    axios
      .get(`https://tunisieimmob.herokuapp.com/api/annoncestop/`)
      .then((response) => {
        console.log(response);
        const data = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <Header active={"accueil"} pushProfile={this.pushProfile} />
        <Banner />
        {/*Recherche */}

        <div className="bg-gray-2 coutf-services-area default-padding">
          <div className="container">
            <div className="row">
              {/* recherche form*/}
              <div>
                <Recherch handleRecherche={this.handleRecherche} />
              </div>
            </div>
          </div>
        </div>
        {/*Part one*/}
        <div className="utf-services-area bg-gray default-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="no-padding utf-site-heading">
                  <h4>
                    ANNONCES <span> PROFESSIONNELLES</span>
                  </h4>
                </div>
                <div className="utf-services-items utf-services">
                  <ItemPro />
                </div>
              </div>
              <div className="col-md-4">
                <Magazine />
              </div>
            </div>
          </div>
        </div>

        <div className="utf-services-area  ">
          <div className="container">
            <div className="row">
            <br></br>
                
              
              
                <div style={{ marginTop: "5px", width:"100%"}} className="col-md-3">
                <a  href="https://play.google.com/store/apps/developer?id=Tunisie+Immobilier">
                <img src="assets/img/application.jpg" />
                </a>
                </div>
               
              </div>
            </div>
            <br></br>
          </div>
        {/*Tous les annonces */}
        <div className="utf-blog-area bg-gray  default-padding">
          <div className="container">
            <div className="row">
              <div style={{ paddingLeft: "5px" }} className="utf-site-heading">
                <h4>
                  Toutes les <span> Annonces</span>
                </h4>
              </div>

              <div className="col-md-8">
                <div className="row">
                  <ul>
                    <li>
                      <Annonce annonces={this.state.annonces} />
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <ul className="row">
                    <div className=" pagination">
                      <div className="row pagination">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <RightBanner />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* les annonces par gouvernaurat*/}
          <div className="utf-blog-area  default-padding">
            <div className="container">
              <div className="row">
                <div className="utf-site-heading">
                  <h4>
                    les Annonces par <span> gouvernaurat</span>
                  </h4>
                </div>
                <div className="col-md-8">
                  <ul className="annonceGouv" >
                    <li>
                      <ul className="equal-height annonce">
                        <Link to={"/annonces/Ariana"}>
                          <li className="gouvernaurat"> ARIANA</li>{" "}
                        </Link>
                        <Link to={"/annonces/Béja"}>
                          <li className="gouvernaurat">BÉJA</li>{" "}
                        </Link>
                        <Link to={"/annonces/Ben Arous"}>
                          <li className="gouvernaurat"> BEN AROUS</li>{" "}
                        </Link>
                        <Link to={"/annonces/Bizerte"}>
                          <li className="gouvernaurat"> BIZERTE</li>{" "}
                        </Link>
                        <Link to={"/annonces/El kef"}>
                          <li className="gouvernaurat"> EL KEF</li>{" "}
                        </Link>
                        <Link to={"/annonces/Gabes"}>
                          <li className="gouvernaurat"> GABES</li>{" "}
                        </Link>
                      </ul>
                    </li>
                    <li>
                      <ul className=" equal-height annonce">
                        <Link to={"/annonces/Gafsa"}>
                          <li className="gouvernaurat"> Gafsa</li>{" "}
                        </Link>
                        <Link to={"/annonces/Jendouba"}>
                          <li className="gouvernaurat">Jendouba</li>{" "}
                        </Link>
                        <Link to={"/annonces/Kairouan"}>
                          <li className="gouvernaurat">Kairouan</li>{" "}
                        </Link>
                        <Link to={"/annonces/Kasserine"}>
                          <li className="gouvernaurat">Kasserine</li>{" "}
                        </Link>
                        <Link to={"/annonces/Kebili"}>
                          <li className="gouvernaurat">Kebili</li>{" "}
                        </Link>
                        <Link to={"/annonces/Mahdia"}>
                          <li className="gouvernaurat">Mahdia</li>{" "}
                        </Link>
                      </ul>
                    </li>
                    <li>
                      <ul className=" equal-height annonce">
                        <Link to={"/annonces/Mannouba"}>
                          <li className="gouvernaurat"> Mannouba</li>{" "}
                        </Link>
                        <Link to={"/annonces/Médenine"}>
                          <li className="gouvernaurat">Médenine</li>{" "}
                        </Link>
                        <Link to={"/annonces/Monastir"}>
                          <li className="gouvernaurat">Monastir</li>{" "}
                        </Link>
                        <Link to={"/annonces/Nabeul"}>
                          <li className="gouvernaurat">Nabeul</li>{" "}
                        </Link>
                        <Link to={"/annonces/Seliana"}>
                          <li className="gouvernaurat">Seliana</li>{" "}
                        </Link>
                        <Link to={"/annonces/Sfax"}>
                          <li className="gouvernaurat">Sfax</li>{" "}
                        </Link>
                      </ul>
                    </li>
                    <li>
                      <ul className=" equal-height annonce">
                        <Link to={"/annonces/Sidi Bouzid"}>
                          <li className="gouvernaurat"> Sidi Bouzid</li>{" "}
                        </Link>
                        <Link to={"/annonces/Sousse"}>
                          <li className="gouvernaurat">Sousse</li>{" "}
                        </Link>
                        <Link to={"/annonces/Tataouine"}>
                          <li className="gouvernaurat">Tataouine</li>{" "}
                        </Link>
                        <Link to={"/annonces/Tozeur"}>
                          <li className="gouvernaurat">Tozeur</li>{" "}
                        </Link>
                        <Link to={"/annonces/Tunis"}>
                          <li className="gouvernaurat">Tunis</li>{" "}
                        </Link>
                        <Link to={"/annonces/Zaghouan"}>
                          <li className="gouvernaurat">Zaghouan</li>{" "}
                        </Link>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="derniere-annonce">
                    <div className="box-sidebar ">
                      <b> DERNIÈRES ANNONCES</b>
                     
                    </div>

                    <b>
                      <div className="col-md-12">
                      <br></br>
                        {this.state.orgAnnonces.length ? (
                          <div>
                            <div className="row">
                            <li className="col-sm-6 toutes-annonces">
                              <Link
                                to={`/annonce/${this.state.orgAnnonces[0]["id"]}`}
                              >
                                <a href=""></a>
                                <img
                                  src={this.state.orgAnnonces[0]["photo"][0]}
                                />
                              </Link>
                            </li>
                            <li className="col-sm-6 toutes-annonces">
                              <p>{this.state.orgAnnonces[0]["titre"]}</p>
                              <h4 className="prix">
                                {" "}
                                {this.state.orgAnnonces[0]["prix"]} TND
                              </h4>
                            </li>
                          </div>
                          
                          <div className="row">
                            <li className="col-sm-6 toutes-annonces">
                              <Link
                                to={`/annonce/${this.state.orgAnnonces[1]["id"]}`}
                              >
                                <a href=""></a>
                                <img
                                  src={this.state.orgAnnonces[1]["photo"][0]}
                                />
                              </Link>
                            </li>
                            <li className="col-sm-6 toutes-annonces">
                              <p>{this.state.orgAnnonces[1]["titre"]}</p>
                              <h4 className="prix">
                                {" "}
                                {this.state.orgAnnonces[1]["prix"]} TND
                              </h4>
                            </li>
                          </div>
                          <div className="row">
                            <li className="col-sm-6 toutes-annonces">
                              <Link
                                to={`/annonce/${this.state.orgAnnonces[2]["id"]}`}
                              >
                                <a href=""></a>
                                <img
                                  src={this.state.orgAnnonces[2]["photo"][0]}
                                />
                              </Link>
                            </li>
                            <li className="col-sm-6 toutes-annonces">
                              <p>{this.state.orgAnnonces[2]["titre"]}</p>
                              <h4 className="prix">
                                {" "}
                                {this.state.orgAnnonces[2]["prix"]} TND
                              </h4>
                            </li>
                          </div>
                            </div>
                          
                        ) : (
                          <div>
                            <img src="assets/img/capture3.JPG" />
                          </div>
                        )}
                      </div>
                    </b>
                  </div>
                  <b></b>
                </div>
                <b></b>
              </div>
              <b></b>
            </div>
            <b></b>
          </div>
          <b></b>
        </div>
        {/* Partie 3 live */}
        <div className="utf-about-area-item about-us bg-gray default-padding">
          <div className="container">
            <div className="row">
              <div className="utf-site-heading">
                <h4>
                  Tunisie Immobilier <span> TV Live</span>
                </h4>
              </div>
              <div className="col-md-8 left-info">
                <div className="embed-responsive embed-responsive-16by9">
                  <ReactHlsPlayer
                    src="https://5ac31d8a4c9af.streamlock.net/tunimmob/_definst_/myStream/playlist.m3u8"
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="auto"
                  />
                </div>
                <br />
                <h5>
                  La Chaîne Tunisie Immobilier TV propose un panel de plusieurs
                  émissions afin de se positionner comme la 1ere chaîne
                  d'information immobilière en Tunisie et être au plus près de
                  l'attente des tunisiens et des maghrébins.La cible de La
                  Chaîne Tunisie Immobilier TV est le grand public tunisien et
                  maghrébin. Notre chaîne immobilière est dédiée pour ceux qui
                  veulent investir dans l'immobilier (tunisiens et étrangers) ou
                  s'informer sur ce vaste secteur.
                </h5>
                <h5>
                  Les professionnels ne sont pas négligés sur la chaîne Tunisie
                  Immobilier tv, elle s’appuie sur leurs conseils et leurs
                  compétences.Tunisie immobilier TV sera le relais
                  d'informations entre le futur acquéreur, les professionnels et
                  les collectivités.
                </h5>
                {/* rtmp://142.44.214.231:1935/tunimmob */}
              </div>
              <div className="col-md-4">
                <RightBanner />
                <br /> <br />
                <div className="box-sidebar">
                  <b> REJOIGNEZ-NOUS !</b>
                  <a>
                    {" "}
                    <img src="assets/img/capture3.JPG" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Fun Factor */}
        <div className="utf-fun-factor-area text-light default-padding bottom-less text-center bg-fixed bg-image shadow ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-3 col-sm-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={2451} data-speed={5000} />
                    <span className="medium">Annonces</span>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={1245} data-speed={5000} />
                    <span className="medium">Vents</span>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={185} data-speed={5000} />
                    <span className="medium">Location</span>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={164} data-speed={5000} />
                    <span className="medium">Application</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        {/* start logo-clients */}
        <LogoClient />
        {/* Start Footer*/}

        <footer className="text-center bg-dark text-light">
          <div className="container">
            <div className="col-md-3">
              <img
                width
                height={110}
                src="assets/img/LOGO-BLANC.png"
                className="logo logo-display"
                alt="Logo"
              />
              <p>
                Depuis 2005, la première revue gratuite d'annonces immobilières
                en Tunisie, avec un contenu 100% composé d'offres immobilières
                de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...).{" "}
                <a href="https://www.tunisieimmob.net/presentation">
                  Plus d'infos ici
                </a>
              </p>
            </div>
            <div className="col-md-3">
              <ul>
                <li
                  id="contact_widget-2"
                  className="widget-container contact_sidebar"
                >
                  <h4>Contact siège à Tunis, tunisia</h4> 
                  <div>
                    <p>
                      <i className="fa fa-building-o" />2 éme étage ,1 rue du
                      royaume d’arabie saoudite
                    </p>
                    <p>
                      <i className="fa fa-phone" />
                      <a href="tel:%28%2B216%29+71+894+500"> 71 894 500</a>{" "}
                      <i className="fa fa-print" />
                      (+216) 71 894 500
                    </p>
                    <p> </p>
                    <p>
                      <i className="fa fa-envelope-o" />
                      <a href="mailto:tunisieimmob@planet.tn">
                        tunisieimmob@planet.tn
                      </a>
                    </p>
                  </div>
                </li>
                <li
                  id="contact_widget-1"
                  className="widget-container contact_sidebar"
                >
                  <h4>Contact siège à Sousse, Tunis</h4>
                  <div className="contact_sidebar_wrap">
                    <p>
                      <i className="fa fa-building-o" />
                      Rue Mongi Slim immeuble Ferdaws Corniche 4000 Sousse
                    </p>
                    <p>
                      <i className="fa fa-phone" />
                      <a href="tel:%28%2B216%29+73+210+566"> 73 210 566</a>{" "}
                      <i className="fa fa-print" />
                      (+216) 73 213 140
                    </p>
                    <p />
                    <p>
                      <i className="fa fa-envelope-o" />
                      <a href="mailto:contact@tunisieimmob.net">
                        contact@tunisieimmob.net
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li
                  id="black-studio-tinymce-7"
                  className="widget-container widget_black_studio_tinymce"
                >
                  <h4>Nos sites</h4>
                  <div className="textwidget">
                    <ul>
                      <li>
                        <i className="fa fa-link" />{" "}
                        <a href="http://www.tunisieimmob.net/">
                          www.tunisieimmob.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.lereflex-immobilier.net/">
                          www.lereflex-immobilier.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.lereflex-agencesimmo.net/">
                          www.lereflex-agencesimmo.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />{" "}
                        <a href="http://www.lereflex-credits.net/">
                          www.lereflex-credits.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.lereflex-investunisie.net/">
                          www.lereflex-investunisie.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />
                        <a href="http://www.tunisieimmobiliertv.net/">
                          www.tunisieimmobiliertv.net
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-link" />{" "}
                        <a href="http://www.lereflex-architecture.net/">
                          www.lereflex-architecture.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Rejoignez-nous !</h4>
              <div className="col-md-3">
                {" "}
                <a href="https://www.facebook.com/www.tunisieimmob.net/">
                  <i className="fab fa-facebook-f" />
                </a>
              </div>
              <div className="col-md-3">
                <a href="https://twitter.com/TvTunisieimmobi">
                  <i className="fab fa-twitter" />
                </a>
              </div>
              <div className="col-md-3">
                {" "}
                <a href="https://www.youtube.com/user/tunisieimmobiliertv">
                  <i className="fab fa-youtube" />
                </a>
              </div>
              <div className="col-md-3">
                {" "}
                <a href="https://www.youtube.com/user/tunisieimmobiliertv">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer2">
          <p>Copyright © 202000 | Tunisie Immobilier. All right reserved. </p>
        </div>
      </div>
    );
  }
}
export default Accueil;
