import React, { Component } from 'react'
import Banner from '../Banner';
import Header from './Header3';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import LogoClient from '../LogoClient';
import { Link } from 'react-router-dom';

export class Dist extends Component {
  render() {
    return (
      <div>
        
        {this.props.dists.map((element) => {
          return (
            <div className="col-sm-3">
              <div className="item">
                <div>
                  <Link to={`/fiche/${element["id"]}`}>
                    <a href="">

                    </a>
                    <img src={element["photo"][0]} />
                  </Link>
                  <span className="pt-dist"> <span className="glyphicon glyphicon-map-marker" /> <b> {element['gouvernorat']} </b></span>
                </div>
                <span> <b> {element['titre']} </b></span>
              </div>
            </div>

            // <div className="col-sm-3 equal-height toutes-annonces">
            //     <div className="item">
            //         <div className="thumb">
            //             <Link to={`/annonce/${element["id"]}`}>
            //                 <a href="">

            //                 </a>
            //                 <img src={element["photo"][0]} />
            //             </Link>
            //             <p className="prix" > {element["prix"]} TND</p>
            //             <p className="position"> <span className="glyphicon glyphicon-map-marker"></span> {element["gouvernorat"]}</p>
            //             <div>
            //                 <p >{element["titre"]}</p>
            //             </div>
            //         </div>
            //         <div>
            //         </div>
            //     </div>
            // </div>
          )
        })}
      </div>
    )
  }
}

class Distributions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allfiches: [],
      topbannerPictures: [],
      offset: 0,
      perPage: 12,
      currentPage: 0,
      dists: []

    }
    this.pushdist = this.pushdist.bind(this)
  }


  pushdist = () => {
    this.props.history.push({
      pathname: '/distributionprofile'
    });
  }

  getAllfiches = () => {
    axios.get(`https://tunisieimmob.herokuapp.com/api/fiche`)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);
        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          allfiches: data,
          dists: slice
        });

      })
      .catch((error) => {
        console.log(error.response);
      })
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });
  };
  loadMoreData() {
    const data = this.state.allfiches;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      dists: slice
    })
  }
  componentDidMount() {
    this.getAllfiches();
  }


  render() {
    console.log("top picures", this.state.topbannerPictures);
    return (
      <div>
        {/* Header */}
        <Header active={"distribution"} pushdist={this.pushdist} />
        {/* End Header */}
        <Banner />

        <div className="default-padding">
          <div className="row">

            <div className=" col-md-4">
              <ul>
                <li>
                  <div className="mapouter">
                    <div className="gmap_canvas"><iframe width={600} height={400} id="gmap_canvas" src="https://maps.google.com/maps?q=immeuble%20ferdaws%20%20corniche%20sousse&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://www.embedgooglemap.net/1/" />
                    </div>
                  </div>
                </li>
                <li>
                  <br />
                  <div>
                    <div className="box-sidebar2">
                      <b> Bande d'annonces </b>
                      <img width={590} height={230} src="./assets/img/point-distribution/2.jpg" />
                    </div>
                    <div>
                      <img src="assets/img/point-distribution/6" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="box-sidebar2">
                      <b> Bande d'annonces </b>
                      <img width={590} height={230} src="/assets/img/point-distribution/1.jpg" />
                    </div>
                    <div>
                      <img src="assets/img/point-distribution/6" alt="" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className=" dist-page col-md-8">
              <ul>
                <li>
                  <div>


                    <div id="carousel-example-generic-dist" className="carousel slide" data-ride="carousel">
                      {/* Indicators */}
                      <ol className="carousel-indicators">
                        {this.state.allfiches.map((element, key) => {
                          if (key === 0) {
                            return (
                              <li data-target="#carousel-example-generic-dist" data-slide-to={0} className="active" />
                            );
                          }
                          else {
                            return (
                              <li data-target="#carousel-example-generic-dist" data-slide-to={key} />
                            );
                          }
                        })}

                      </ol>

                      {/* Wrapper for slides */}
                      <div className=" dist-carousel carousel-inner" role="listbox">
                        {this.state.allfiches.map((element, key) => {
                          if (key === 0) {
                            return (
                              <div className="item active">
                                <img src={element["photo"][0]} alt="..." />
                              </div>
                            );
                          }
                          else {
                            return (
                              <div className="item">
                                <img src={element["photo"][0]} alt="..." />
                              </div>
                            );
                          }

                        })}

                      </div>
                      {/* Controls */}
                      <a className="left carousel-control" role="button" data-slide="prev">
                        <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a className="right carousel-control" role="button" data-slide="next">
                        <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <br />
                  {/* <form>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option>  Toutes Type
                    </option>
                          <option> Café </option>
                          <option> Centre Médical
                    </option>
                          <option>Fast Food
                    </option>
                          <option> Patisserie
                    </option>
                          <option> Restaurant</option>
                          <option> Salon De Thé </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option> Toutes villes
                    </option>
                          <option> Ariana</option>
                          <option> Béja</option>
                          <option> Ben Arous
                    </option>
                          <option>Bizerte</option>
                          <option> El Kef</option>
                          <option> Gabes</option>
                          <option> Gafsa</option>
                          <option> Jendouba
                    </option>
                          <option> Kairouan
                    </option>
                          <option> Kasserine
                    </option>
                          <option>Kebili</option>
                          <option> Mahdia</option>
                          <option>Mannouba</option>
                          <option> Médenine</option>
                          <option> Monastir
                    </option>
                          <option> Nabeul</option>
                          <option> Seliana</option>
                          <option> Sfax</option>
                          <option> Sidi Bouzid
                    </option>
                    Sousse
                    <option>Tataouine
                    </option>
                          <option>Tozeur</option>
                          <option> Tunis</option>
                          <option> Zaghouan
                    </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <a className="btn btn-theme effect btn-md " href="#"> Chercher <span className="glyphicon glyphicon-search" aria-hidden="true" /></a>
                      </div>
                    </div>
                  </form>
                 */}
                </li>
                <li>
                  <div className="col-md-12">
                    <div className="row">
                      <Dist dists={this.state.dists} />
                    </div>

                    <div className="row">
                      <div className="col-sm-3">
                        <div className=" pagination" >
                          <div className="row pagination">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>




                </li>

              </ul>
            </div>

          </div>
        </div>

        {/* start logo-clients */}
        <LogoClient />

        {/* Start Footer*/}
        <footer className="text-center bg-dark text-light">
          <div className="container">
            <div className="col-md-3">
              <img width height={110} src="assets/img/LOGO-BLANC.png" className="logo logo-display" alt="Logo" />
              <p>Depuis 2005, la première revue gratuite d'annonces immobilières en Tunisie, avec
                un
                contenu 100% composé d'offres immobilières de vente et location de biens immobiliers (maisons,
                appartements, terrains, fonds de commerce, ...). <a href="https://www.tunisieimmob.net/presentation/">Plus
                  d'infos ici</a></p>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="contact_widget-2" className="widget-container contact_sidebar">
                  <h4>Contact siège à Tunis</h4>
                  <div>
                    <p><i className="fa fa-building-o" />2 éme étage ,1 rue du royaume d’arabie
                      saoudite
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+71+894+500"> 71
                      894 500</a> <i className="fa fa-print" />(+216) 71 894 500</p>
                    <p> </p>
                    <p><i className="fa fa-envelope-o" /><a href="mailto:tunisieimmob@planet.tn">tunisieimmob@planet.tn</a></p>
                  </div>
                </li>
                <li id="contact_widget-1" className="widget-container contact_sidebar">
                  <h4>Contact siège à Sousse</h4>
                  <div className="contact_sidebar_wrap">
                    <p><i className="fa fa-building-o" />Rue Mongi Slim immeuble Ferdaws Corniche
                      4000
                      Sousse
                    </p>
                    <p><i className="fa fa-phone" /><a href="tel:%28%2B216%29+73+210+566"> 73
                      210 566</a> <i className="fa fa-print" />(+216) 73 213 140</p>
                    <p />
                    <p><i className="fa fa-envelope-o" /><a href="mailto:contact@tunisieimmob.net">contact@tunisieimmob.net</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li id="black-studio-tinymce-7" className="widget-container widget_black_studio_tinymce">
                  <h4>Nos sites</h4>
                  <div className="textwidget">
                    <ul>
                      <li><i className="fa fa-link" /> <a href="http://www.tunisieimmob.net/">www.tunisieimmob.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-immobilier.net/">www.lereflex-immobilier.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-agencesimmo.net/">www.lereflex-agencesimmo.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-credits.net/">www.lereflex-credits.net</a>
                      </li>
                      <li><i className="fa fa-link" /><a href="http://www.lereflex-investunisie.net/">www.lereflex-investunisie.net</a></li>
                      <li><i className="fa fa-link" /><a href="http://www.tunisieimmobiliertv.net/">www.tunisieimmobiliertv.net</a></li>
                      <li><i className="fa fa-link" /> <a href="http://www.lereflex-architecture.net/">www.lereflex-architecture.net</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Rejoignez-nous !</h4>
              <div className="col-md-3"> <a href="https://www.facebook.com/www.tunisieimmob.net/"><i className="fab fa-facebook-f" /></a>
              </div>
              <div className="col-md-3">
                <a href="https://twitter.com/TvTunisieimmobi"><i className="fab fa-twitter" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-youtube" /></a>
              </div>
              <div className="col-md-3"> <a href="https://www.youtube.com/user/tunisieimmobiliertv"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer2">
          <p>Copyright © 2020 | Tunisie Immobilier. All right reserved. </p>
        </div>


      </div>
    )
  }
}
export default Distributions