import React, { Component } from 'react';
import axios from 'axios';

export default class Magazine extends Component {
    constructor(props){
        super(props);
        this.state={
            magazineActuel: {},
        }
    }
    getMagzine = () => {
        axios.get('https://tunisieimmob.herokuapp.com/api/magazine')
            .then((response) => {
                this.setState({ magazineActuel: response.data[0] })
            })
            .catch((error) => {
                console.log(error.response);
            })
    };
    componentDidMount (){
        this.getMagzine();
    }
    render() {
        return (
            <div>
                <div className="pf-item video development  right-info">
                                    <div className="box-sidebar"> <b>
                                    DERNIÈRE ÉDITION N° {this.state.magazineActuel['numero']}</b>
                                    </div>
                                    <div>
                                        <a href={this.state.magazineActuel['url']} target="_blank" > <img  width={320} height="430" src={this.state.magazineActuel['image']}  />
                                        </a>
                                    </div>
                                </div>
            </div>
        )
    }
}
