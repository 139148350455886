import React, { Component } from "react";
import {
    Input,
    Layout,
    Menu,
    Select,
    DatePicker
} from "antd";
import {
    UserOutlined,
    BarsOutlined,
} from "@ant-design/icons";
import S3 from "react-aws-s3";
import moment from 'moment';
import ReactPaginate from 'react-paginate';

import Header2 from "./Header4"
import axios from "axios";


import "antd/dist/antd.css";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;

const config = {
    bucketName: "tunisie-immob",
    /*  dirName: 'media', optional */
    region: "eu-west-3",
    accessKeyId: "AKIAXVWFAUBHFUM7UQ24",
    secretAccessKey: "2w/tBnEYOmQgGThMEdS7JiccnMeV0DP4fXF1sYbi",
    /* s3Url: 'https:/your-custom-s3-url.com/',  optional */
};
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const layout2 = {
    wrapperCol: {
        span: 20,
    },
};
const layout3 = {
    wrapperCol: {
        span: 24,
    },
};
const layoutt = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 16,
    },
};

export class Fiche extends Component {


    render() {
        return (
            <div>
                {this.props.allfiches.map((element) => {
                    return (
                        <tr className="SingleAnnonce" >
                            <td style={{ boxShadow: "0 0 2em rgb(196, 196, 196)" }} >
                                <div className="col-md-12" >
                                    <div className="col-md-6" style={{ cssFloat: "left", padding: "0px", width: "300px" }}>
                                        <Link to={`/fiche/${element['id']}`} >  < img src={element['photo'][0]}
                                        />
                                        </Link>
                                    </div>
                                    <div className="col-md-6" style={{ padding: "5px", width: "300px" }} >
                                        <h5>
                                            {element['titre']}
                                        </h5>
                                        <br />
                                        <div>
                                        </div>
                                        fiche dans {element['categorie']} et {element['type']}
                                        <br></br>
                                        <br></br>
                                        <br></br>

                                        ville : {element['gouvernorat']}  ,
                                        <hr style={{
                                        }}
                                        />
                                        <br />

                                        <br />
                                    </div>
                                </div>

                            </td>
                        </tr>


                    )
                })}
            </div>
        )
    }
}


export default class Mafiche extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualUser: "",
            fiche: {},
            fiches: [],
            offset: 0,
            orgfiches: [],
            perPage: 4,
            currentPage: 0
        };
    }

    handleLogOut = () => {
        this.props.history.push({
            pathname: '/'
        });
        localStorage.removeItem("id")
        localStorage.removeItem("username")
        localStorage.removeItem("password")
        localStorage.removeItem("email")
    }
    getfiches(userid) {
        axios.get(`https://tunisieimmob.herokuapp.com/api/fiche`)
            .then((response) => {
                console.log(response.data);
                const data = response.data;
                var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgfiches: response.data,
                    fiches: slice,
                })
            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    loadMoreData() {
        const data = this.state.orgfiches;

        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            fiches: slice
        })
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    componentDidMount() {
        window.scrollTo(0, 0)
        const userid = localStorage.getItem("id-gerant");
        this.setState({ actualUser: userid });
        this.getfiches(userid);
    }


    render() {
        const { uploading, fileList, ajoutAnnonce } = this.state;
        console.log(this.state.imageUrls)
        return (
            <div style={{ marginTop: "87px" }}>
                <Header2 handleLogOut={this.handleLogOut} />
                <Layout>
                    <Sider
                        style={{
                            overflow: "auto",
                            height: "100vh",
                            position: "fixed",
                            left: 0,
                        }}
                    >
                        <div className="logo" />
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={["3"]}>
                            <Menu.Item key="1" icon={<UserOutlined />}>
                                <Link to="/distributionprofile" >Profile et Logo</Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<BarsOutlined />}>
                                <Link to="/Editfiche" >Modifier Ma fiche</Link>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<BarsOutlined />}>
                                <Link to="/mafiche" > Ma fiche</Link>
                            </Menu.Item>

                            {/* <Menu.Item key="4" icon={<HeartOutlined />}>
                favoris
              </Menu.Item>
              <Menu.Item key="5" icon={<FileSearchOutlined />}>
                Recherche enregistrées
              </Menu.Item> */}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout" style={{ marginLeft: 200 }}>
                        <Header className="site-layout-background" style={{ padding: 0 }} >
                            <h1 style={{ color: "white" }} >Gestion fiche distributeur </h1>
                            <h4 style={{ color: "white" }}  >Pour avoir une belle fiche, commencer d'abord par ajouter votre logo dans "Profile et Logo", ensuite ajouter votre fiche dans "Ajouter une Fiche"</h4>
                        </Header>
                        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                            <div className="utf-services-area bg-gray default-padding">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="utf-site-heading">
                                            <h4>Ma <span> fiche</span></h4>
                                        </div>

                                        <br></br>
                                        <br>
                                        </br>
                                        <div className=" row col-md-8 col-sm-2 right-info">

                                            <hr class="annonces-line" />
                                            <br></br>
                                        </div>
                                        <table
                                            style={{
                                                borderCollapse: "separate",
                                                borderSpacing: "0px 20px",
                                            }}
                                        >
                                            <tbody>

                                                <Fiche allfiches={this.state.fiches} />

                                            </tbody>
                                        </table>
                                        <div className="row" >
                                            <div className=" pagination" >
                                                <div className="row pagination">
                                                    <ReactPaginate
                                                        previousLabel={"<"}
                                                        nextLabel={">"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}
